import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import { FaFile, FaFolder, FaTrash, FaDownload, FaTimes } from "react-icons/fa";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardBody
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import FileViewer from 'react-file-viewer';
import { listFiles, fileTypeAttrGetter, fileContent, getExtensionByFileName, getNameFormPath, deleteFile } from "Controller/File.js";
import axios from "axios";

import code from "assets/img/code.pdf";
import cat from "assets/img/cat.jfif";
import piano from "assets/img/piano.mp4";
import './CusFileViewer.scss';

class CusTables extends React.Component {
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      currentFile: "",
      // currentFolder:listFiles({path: this.props.location.pathname}).files,
    };
    this.goBack = this.goBack.bind(this);
  }
  download(key) {
    if (key[0] == "/") {
      key = key.slice(1);
    }
    fileContent(key).then(url => {
      window.location.href = url;
    });
  }
  componentDidMount(e) {
    let key = this.props.data.filter(x => x.key == this.props.path)[0].key;
    try {
      fileContent(key).then(url => {
        this.setState({ currentFile: url });
      });
    } catch (error) {
      this.setState({ currentFile: error });
    }
  }
  goBack() {
    this.props.history.goBack();
  }
  onError(e) {
    console.log(JSON.stringify(e));
  }

  deleteObject(path) {
    if (window.confirm('Are you sure? 確定?') === true) {
      deleteFile(path, { level: "private" }).then(
        res => {
          window.location.href = window.location.href;
        });
        this.goBack()
    }
  }

  render() {
    return (
      <>
        {this.state.alert}
        <SimpleHeader path={this.props.path} {...this.props} refresh={true} isfile={true} />
        <Container className="mt--6" fluid>
          <Row >
            <Col lg="9">
              <Card>
                <center>
                  <div style={{ height: "400px", overflow: "auto" }}>
                    {this.state.currentFile == "" ? "loading" : <FileViewer filePath={this.state.currentFile} fileType={getExtensionByFileName(this.props.path).split(".").pop()} onError={this.onError} />}
                  </div>
                </center>
              </Card>
            </Col>
            <Col lg="3">
              <Card>
                <center>
                  <div style={{ height: "400px", overflow: "auto" }}>
                    <CardHeader>
                      <Row className="title-size">
                        <Col lg="10" className="p-0 justify-content-center">
                          {getNameFormPath(this.props.path)}
                        </Col>
                        <Col lg="2" className="p-0 align-self-center">
                          <a className="button" onClick={this.goBack} >
                            <FaTimes />
                          </a>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row className="mx-3 detail-size">
                        <Col lg="4" className="text-left p-0 title">
                          File type </Col>
                        <Col lg="8" className="text-left p-0">
                          {getExtensionByFileName(this.props.path).split(".").pop()}
                        </Col>
                      </Row>
                      <Row className="mx-3 my-4 detail-size">
                        <Col lg="4" className="text-left p-0 title">
                          Creat time </Col>
                        <Col lg="8" className="text-left p-0">
                          {new Date(this.props.data.filter(x => x.key == this.props.path)[0].lastModified).toString()}
                        </Col>
                      </Row>
                      <Row className="mx-3 my-4 detail-size">
                        <Col lg="4" className="text-left p-0 title">
                          Size </Col>
                        <Col lg="8" className="text-left p-0">
                          {this.props.data.filter(x => x.key == this.props.path)[0].size}</Col>
                      </Row>
                      <Row className="mx-3 my-4 detail-size">
                        <Col lg="4" className="text-left p-0 title">
                          Path </Col>
                        <Col lg="8" className="text-left p-0">
                          {this.props.path}
                        </Col>
                      </Row>
                      <Row className="mx-3 my-4 detail-size justify-content-end">
                        <a><FaTrash className="mr-3 button" onClick={() => this.deleteObject(this.props.path)} /> {"   "}<FaDownload className="mr-3 button" onClick={() => this.download(this.props.path)} />{" "}</a>
                      </Row>
                    </CardBody>
                  </div>
                </center>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default CusTables;
