import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText
} from "reactstrap";
import { useHistory } from "react-router-dom";
import gitLogo from "assets/img/icons/common/github.svg"
import googleLogo from "assets/img/icons/common/google.svg"
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Auth } from 'aws-amplify';

import "./Register.scss"


class ForgotPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorMsg: null,
      username: ''
    }
    this.onChange = this.onChange.bind(this)
    this.backToSignIn = this.backToSignIn.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
  }

  onChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  backToSignIn() {
    this.props.history.push("/auth/login");
  }

  resetPassword() {
    this.props.history.push({
      pathname: "/auth/ResetPassword",
      state: { email: this.state.username }
    });
  }

  handleError(err) {
    //input is row['fileName'] already

    console.log(err.message);
    //   if (fileType == "folder" && !isfolder)
    //     return <FaFile className="fileIcon" />;

    switch (err.message) {
      case "Username cannot be empty":
        this.setState({ errorMsg: "Username cannot be empty \n 用户名稱不能為空值" });
        break;
      case "Username/client id combination not found.":
        this.setState({ errorMsg: "Username is not find \n 找不到用戶名" });
        break;
      case "Cannot reset password for the user as there is no registered/verified email or phone_number":
        this.setState({ errorMsg: "Username is not find \n 找不到用戶名" });
        break;
      default:
        this.setState({
          error: err,
          errorMsg: err.message
        })
    }
  }

  async forgotPassword(e) {
    e.preventDefault()
    try {
      await Auth.forgotPassword(this.state.username);
      alert("A confirmation code is sent to your email!\n驗証碼已發送至你的電子郵箱!");
      this.resetPassword();
      /* Once the user successfully confirms their account, update form state to show the sign in form*/
    }
    catch (error) {
      this.handleError(error);
    }
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Forgot your password?"
          lead="please fill in your user name and we will send your confirmation code to reset your password"
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">

                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-left text-muted mb-4">
                    <h3>Forgot your password? 忘記密碼？</h3>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <div className="ml-2">
                        <h5>Email 電郵地址</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          onChange={this.onChange}
                          placeholder="Example: test@test.com"
                          type="text" />
                      </InputGroup>
                      {
                        this.state.errorMsg
                          ?
                          <FormText color="danger">
                            {this.state.errorMsg.split("\n").map(word => <>{word}<br /></>)}</FormText>
                          :
                          null
                      }
                    </FormGroup>
                    <Row className="align-items-center">
                      <Col>
                        <Button className="mt-2 btn px-5 float-right " color="info" type="button" onClick={this.forgotPassword}>
                          Submit
                          <br />
                          提交
                        </Button>
                        <Button className="mt-2 btn px-3 mr-2 float-right " color="secondary" type="button" onClick={this.backToSignIn}>
                          Back to sign in
                          <br />
                          返回登錄
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )


  }
}

export default ForgotPassword;
