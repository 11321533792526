import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText
} from "reactstrap";

import gitLogo from "assets/img/icons/common/github.svg"
import googleLogo from "assets/img/icons/common/google.svg"
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import modifyProfile from "views/pages/createUserScript";

import "./Register.scss"

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorMsg: null,
      username: '',
      password: '',
      verificationCode: '',
      confirmPW: ''
    }
    this.onChange = this.onChange.bind(this)
    this.waitForConfirm = this.waitForConfirm.bind(this)
    this.backToSignIn = this.backToSignIn.bind(this)
    this.signUp = this.signUp.bind(this)
    this.handleError = this.handleError.bind(this)
  }

  onChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  waitForConfirm() {
    this.props.history.push({
      pathname: "/auth/confirmRegister",
      state: { username: this.state.username }
    });
  }

  backToSignIn() {
    this.props.history.push("/auth/login");
  }



  handleError(err) {
    //input is row['fileName'] already

    console.log(err.message);
    //   if (fileType == "folder" && !isfolder)
    //     return <FaFile className="fileIcon" />;

    switch (err.message) {
      case "Password cannot be empty":
        this.setState({ errorMsg: "Password cannot be empty \n 密碼不能為空值" });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      case "An account with the given email already exists.":
        this.setState({ errorMsg: "An account with the given email already exists. \n 這個電子郵件地址的帳戶已存在。" });
        break;
      case "Error creating account":
        this.setState({ errorMsg: "please enter Username and Password \n 請輸入用户名稱和密碼" });
        break;
      case "Password did not conform with policy: Password must have uppercase characters":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      default:
        this.setState({
          errorMsg: err.message
        })
    }
  }


  async signUp(e) {
    e.preventDefault()
    // if (this.state.password !== "undefined" && this.state.confirmPW !== "undefined") {
    if (this.state.password !== this.state.confirmPW) {
      this.setState({ errorMsg: "Passwords don't match.\n 密碼不相同" });
    }
    // }
    else {
      try {
        await Auth.signUp(this.state.username, this.state.password);
        this.waitForConfirm();
        alert("A confirmation code is sent to your email!\n驗証碼已發送至你的電子郵箱!")
      }
      catch (err) {
        this.handleError(err);
      }
    }
  }

  render() {
    return (
      <>

        <AuthHeader
          title="Create an account"
          lead="Use these awesome forms to login or create new account in your project for free."
        />
        <button onClick={()=>{modifyProfile();console.log(123);}}>123</button>
        <Container className="mt--8 pb-1">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">

                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-left text-muted mb-4">
                    <h3>Sign up 註冊</h3>
                  </div>
                  <Form role="form">
                    <FormGroup>
                      <div className="ml-2">
                        <h5>Email 電郵地址</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          onChange={this.onChange}
                          placeholder="Example: test@test.com"
                          type="email"
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <div className="ml-2">
                        <h5>Password 密碼</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Min 6 char or num, at least 1 upper and 1 lower"
                          name="password"
                          type="password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      <div className="ml-2">
                        <h5>Confirm Password 確認密碼</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Min 6 char or num, at least 1 upper and 1 lower"
                          name="confirmPW"
                          type="password"
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      {
                        this.state.errorMsg
                          ?
                          <FormText color="danger">
                            {this.state.errorMsg.split("\n").map(word => <>{word}<br /></>)}</FormText>
                          :
                          null
                      }
                    </FormGroup>
                    <Row className="align-items-center">
                      <Col>
                        <Button className="mt-2 btn px-3 float-right " color="info" type="button" onClick={this.signUp}>
                          Create account
                          <br />
                          創建賬戶
                        </Button>

                        <Button className="mt-2 btn px-3 mr-2 float-right " color="secondary" type="button" onClick={this.backToSignIn}>
                          Back to sign in
                          <br />
                          返回登錄
                         </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}


export default Register;
