import React from "react";
import { Storage } from 'aws-amplify';
import _ from 'lodash'
import { withAuthenticator, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from "@aws-amplify/ui-react";


class S3Test extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileTree: null
    }

    this.getPrivateFiles = this.getPrivateFiles.bind(this)
    this.processStorageList = this.processStorageList.bind(this)
    // this.recursiveFileGen = this.recursiveFileGen.bind(this)
  }

  onChange(e) {
    console.log('uploading file...');
    const file = e.target.files[0];
    // Storage.put(`folder-1/folder-1-1/${file.name}`, file, {
    Storage.put(`folder-2/folder-1-2/${file.name}`, file, {
      level: 'private',
      progressCallback(progress) {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        console.log(`Uploaded%: ${parseFloat(100 * progress.loaded / progress.total).toFixed(2)}%`);
      },
    })
      .then(result => {
        console.log('file upload success');
        console.log(result)
      })
      .catch(err => console.log(err));
  }

  getPublicFiles() {
    console.log('getting public files...');
    Storage.list('') // '' is to get all files
      .then(result => {
        console.log('list of public files:');
        console.log(result);
      })
  }

  getPrivateFiles() {
    console.log('getting private files...');
    Storage.list('', { level: 'private' }) // '' is to get all files
      .then(result => {
        console.log('list of private files:');
        console.log(JSON.stringify(result));
        let fileTree = this.processStorageList(result)
        console.log(JSON.stringify(fileTree));
        this.setState({ fileTree: fileTree })
      })
  }

  processStorageList(results) {
    const filesystem = {}
    // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
    const add = (source, target, item) => {
      const elements = source.split("/");
      const element = elements.shift();
      if (!element) return // blank
      target[element] = target[element] || { __data: item }// element;
      if (elements.length) {
        target[element] = typeof target[element] === "object" ? target[element] : {};
        add(elements.join("/"), target[element], item);
      }
    };
    results.forEach(item => add(item.key, filesystem, item));
    return filesystem
  }

  genTree(results) {

    const RecursiveFileGen = ({ obj }) => {
      // console.log('in recursiveGen', obj);

      return (
        Object.keys(obj).map(key => {
          const value = obj[key]
          // console.log('looking at obj');
          // console.log('key: ', key)
          // console.log('value: ', value)

          if (key === "__data") { return null }

          else if (!key.includes(".")) {
            // this is a folder
            return (
              <div className="folder-div">
                <div className="font-weight-bold">{key}</div>
                <div className="ml-3">
                  {RecursiveFileGen({ obj: value })}
                </div>
              </div>
            )
          }

          else if (value["__data"]["size"] !== 0) {
            // this is a file
            return (
              <div className="file-div">
                <a href={`${value["__data"]["key"]}`}>{`${key}`}</a>
              </div>
            )
          }
        })
      )
    }
    // return (recursiveFileGen(results))
    return (
      <>
        <div>root</div>
        <RecursiveFileGen obj={results} />
      </>
    )
  }


  render() {
    return (
      <>
        <AmplifyAuthenticator>
          <AmplifySignUp
            slot="sign-up"
            usernameAlias='email'
            formFields={[
              { type: "email" },
              {
                type: "password",
                // label: "Custom Password Label",
                // placeholder: "custom password placeholder"
              },
            ]}
          />
          <div>S3 Testing page</div>
          <input
            type="file"
            // accept='image/png'
            onChange={(evt) => this.onChange(evt)}
          />

          <div>
            <div>Test on File Listing (Public)</div>
            <button onClick={this.getPublicFiles}>Get public files</button>
          </div>

          <div>
            <div>Test on File Listing (Private)</div>
            <button onClick={this.getPrivateFiles}>Get private files</button>
          </div>

          <div>
            {
              this.state.fileTree ?
                this.genTree(this.state.fileTree) :
                null
            }
          </div>
          <AmplifySignOut />

        </AmplifyAuthenticator>
      </>
    )
  }
}

export default S3Test;