/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:b8ff56f2-0928-431c-95d6-22ab5fae75e8",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_EOR5wlt9q",
    "aws_user_pools_web_client_id": "1ro8f6dkjoti2r0qo2obgtlmm1",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://cwyuvgkp2g.execute-api.ap-southeast-1.amazonaws.com/dev",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "meshdrive01439-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
