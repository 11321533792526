import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import Login from "views/pages/Login.js";
import Register from "views/pages/Register.js";
import ConfirmSignUp from "views/pages/ConfirmSignUp.js";
import ForgotPassword from "views/pages/ForgotPassword.js";
import ResetPassword from "views/pages/ResetPassword.js";

class Auth extends React.Component {
  componentDidMount() {

  }

  render() {
    return (
      <>
        <div className="main-content" ref="mainContent">
          <AuthNavbar />
          <Switch>
            {/* {this.getRoutes(routes)} */}
            <Route
              path="/auth/register"
              component={withRouter(Register)}
            />
            <Route
              exact
              path="/auth/confirmregister"
              component={withRouter(ConfirmSignUp)}
            />
            <Route
              path="/auth/confirmregister/:name/:code"
              component={withRouter(ConfirmSignUp)}
            />
            <Route
              path="/auth/forgotpassword"
              component={withRouter(ForgotPassword)}
            />
            <Route
              path="/auth/ResetPassword/:name/:code"
              component={withRouter(ResetPassword)}
            />
            <Route
              exact
              path="/auth/ResetPassword"
              component={withRouter(ResetPassword)}
            />
            <Route
              path="/auth/login"
              component={withRouter(Login)}
            />
            <Redirect from="*" to="/auth/login" />
          </Switch>
          <AuthFooter />
        </div>
      </>
    )
  }
}

export default Auth;