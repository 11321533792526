import React from 'react';
import ReactDOM from 'react-dom';
// import "react-perfect-scrollbar/dist/css/styles.css";
// // plugins styles downloaded
// import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
// import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
// import "assets/vendor/select2/dist/css/select2.min.css";
// import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
// import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
// core styles
// import "assets/scss/argon-dashboard-pro-react.scss?v1.1.0";
import 'assets/scss/argon-dashboard-pro-react.scss'
import './index.scss';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Switch, Redirect, withRouter } from "react-router-dom";
// import IndexView from "views/Index.js";
import AuthLayout from "layouts/Auth.js";
import Main from "views/pages/Main.js";
import Settings from "views/pages/Settings.js";
import VideoChat from "views/pages/VideoChat.js";
import Profile from "views/pages/Profile.js";
// import Test from "views/pages/Test.js";

import S3Test from "views/pages/S3Test.js";
// import axios from "axios";
// import AdminLayout from "layouts/Admin.js";

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

ReactDOM.render(
  <div id="body">
    <BrowserRouter>
      <Switch>
        {/* <Route path="/admin" render={props => <AdminLayout {...props} />} /> */}
        {/* <Route path="/rtl" render={props => <RTLLayout {...props} />} /> */}
        <Route path="/auth" component={withRouter(AuthLayout)} />
        <Route path="/settings" render={props => <Settings {...props} />} />
        <Route path="/profile" render={props => <Profile {...props} />} />
        <Route path="/videochat" render={props => <VideoChat {...props} />} />
        {/* <Route path="/testing" render={props => <Test {...props} />} /> */}
        <Route exact path="/" component={props => <Redirect to="/main" />} />
        <Route exact path="/main" component={withRouter(Main)} />
        <Route exact path="/main/*" component={withRouter(Main)} />
        {/* <Route path="/" render={props => <IndexView {...props} />} /> */}
        {/* <Redirect from="*" to="/auth" /> */}
        {/* <Redirect from="*" to="/main" /> */}
        <Route exact path="/S3Test" component={withRouter(S3Test)} />
      </Switch>
    </BrowserRouter>
  </div>,
  document.getElementById('root')
);

// <React.StrictMode>
//   <App />
// </React.StrictMode>,

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
