import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  FormFeedback, FormText,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
// bvvf
import gitLogo from "assets/img/icons/common/github.svg"
import googleLogo from "assets/img/icons/common/google.svg"
import { Auth } from 'aws-amplify';
import { useHistory } from "react-router-dom";
import { withAuthenticator, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from "@aws-amplify/ui-react";
import { FaGalacticSenate } from "react-icons/fa";

// let setState = { username: '', password: '' };

// function onChange(e) {
//   setState = { ...setState, [e.target.name]: e.target.value };
// }

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorMsg: null,
      username: '',
      password: null,
      logging: false
    }

    this.onChange = this.onChange.bind(this)
    this.backToSignUp = this.backToSignUp.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
    this.signIn = this.signIn.bind(this)
    this.loggedIn = this.loggedIn.bind(this)
  }

  onChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  backToSignUp() {
    this.props.history.push("/auth/register");
  }

  forgotPassword() {
    this.props.history.push("/auth/forgotpassword");
  }

  loggedIn() {
    this.props.history.push("/main");
  }

  handleError(err) {
    //input is row['fileName'] already

    console.log(err.message);
    //   if (fileType == "folder" && !isfolder)
    //     return <FaFile className="fileIcon" />;

    switch (err.message) {
      case "Username cannot be empty":
        this.setState({ errorMsg: "Username cannot be empty \n 用户名稱不能為空值" });
        break;
      case "Custom auth lambda trigger is not configured for the user pool.":
        this.setState({ errorMsg: "Please enter your password \n 請輸入密碼" });
        break;
      case "Username/client id combination not found.":
        this.setState({ errorMsg: "Username is not find \n 用戶名不存在" });
        break;
      case "User does not exist.":
        this.setState({ errorMsg: "Incorrect username or password \n 用戶名或密碼錯誤" });
        break;
      case "Incorrect username or password.":
        this.setState({ errorMsg: "Incorrect username or password \n 用戶名或密碼錯誤" });
        break;
      default:
        this.setState({
          error: err,
          errorMsg: err.message
        })
    }
  }

  /* Sign in function */
  async signIn(e) {
    e.preventDefault()

    this.setState({
      logging: true
    });

    Auth.signIn(this.state.username, this.state.password).then(res => {
      /* Once the user successfully signs in, update the form state to show the signed in state */
      console.log("loggedIn");
      this.loggedIn()
      // BackToSignUp();
    }).catch(err => {

      console.log(err);
      // alert(err.message)

      this.setState({
        logging: false
      });

      if (err.code === "UserNotConfirmedException") {
        this.props.history.push("/auth/confirmregister");
      }

      if (err.message !== "Username cannot be empty") {
        this.setState({
          password: ""
        })
      }

      this.handleError(err);

    })
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Create an account"
          lead="Use these awesome forms to login or create new account in your project for free."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">

                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-left text-muted mb-4">
                    <h3>Sign in 登入</h3>
                  </div>
                  <Form role="form"
                    onSubmit={this.signIn}
                  >
                    <FormGroup>
                      <div className="ml-2">
                        <h5>Email 電郵地址</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-circle-08" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          onChange={this.onChange}
                          placeholder="Example: test@test.com"
                          type="text" />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup>
                      <div className="ml-2">
                        <h5>Password 密碼</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Min 6 char or num, at least 1 upper and 1 lower"
                          type="password"
                          name="password"
                          value={this.state.password}
                          onChange={this.onChange}
                        />
                      </InputGroup>
                      {
                        this.state.errorMsg ?
                          <FormText color="danger">{this.state.errorMsg.split("\n").map(x => <>{x}<br /></>)}</FormText> :
                          null
                      }
                    </FormGroup>
                    <Row className="align-items-center">
                      <Col xg="6">
                        <a className="text-left text-primary" type="button" onClick={this.forgotPassword}>
                          <small>Forgot password? 忘記密碼？</small>
                        </a>
                        <a className="mt-2 text-left" type="button" onClick={this.backToSignUp}>
                          <small>Create new account 建立新帳戶</small>
                        </a>
                      </Col>
                      <Col xg="6">
                        {
                          this.state.logging == true ?
                            <Button className="mt-2 btn px-5 float-right " color="info" type="submit" disabled="disabled">
                              Signing in...
                              <br />
                              登錄中...
                            </Button>
                            :
                            <Button className="mt-2 btn px-5 float-right " color="info" type="submit">
                              Sign In
                              <br />
                              登入
                            </Button>
                        }
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}
export default Login;
