import React from "react";
// react library for routing
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
// core components
import NormalNavbar from "components/Navbars/NormalNavbar.js";
import FileFooter from "components/Footers/FileFooter.js";
import Sidebar from "components/Sidebar/MainSidebar.js";
import CusTable from "components/Body/CusTable.js"
import CusTableFSR from "components/Body/CusTableForSearchResult.js"
import { listFiles, convertFilesToAntdTree, searchByKeyword, processStorageList, fileContent, uploadFile, deleteFile } from "Controller/File.js";
import CusFileViewer from "components/Body/CusFileViewer.js";
import logoBlue from 'assets/img/brand/mesh-drive-logo-blue.png';
import Loading from 'components/Body/Loading.js';
import { withAuthenticator, AmplifyAuthenticator, AmplifySignUp, AmplifySignOut } from "@aws-amplify/ui-react";

class Main extends React.Component {
  // state = {
  //   sidenavOpen: d
  // };
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }
  setData(data) {
    this.setState({ data: data });
  }
  genData(path, callback) {
    listFiles({ path: "" }).then(res => {
      listFiles({ path: path }).then(res2 => {
        console.log(res);
        if (res.filter(file => file.key == "profile").length == 0) {
          uploadFile("profile", null, { level: 'private' }).then(_ => {
            this.genData(path, callback)
          })
        }
        if (res2.length != 0) {
          this.setState({ data: res });
          callback(res);
        }

      })
    }).catch(err => {
      if (err.response != null)
        if (err.response.status == "403") {
          this.props.history.push({
            pathname: '/auth/login'
          });
        }
    });
  }
  componentDidMount(e) {
    try {
      this.genData(this.props.location.pathname.split("/").slice(2).join("/"), this.setData.bind(this));
    } catch (error) {
      this.setState({ data: error });
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   listFiles({ path: this.state.currentPath }).then(res => {
  //     this.setState({
  //       currentPath: nextProps.currentPath,
  //       data: res
  //     });
  //   })
  // }

  // No need sidenav for now - (Lawrence) 

  // // toggles collapse between mini sidenav and normal
  // toggleSidenav = e => {
  //   if (document.body.classList.contains("g-sidenav-pinned")) {
  //     document.body.classList.remove("g-sidenav-pinned");
  //     document.body.classList.add("g-sidenav-hidden");
  //   } else {
  //     document.body.classList.add("g-sidenav-pinned");
  //     document.body.classList.remove("g-sidenav-hidden");
  //   }
  //   this.setState({
  //     sidenavOpen: !this.state.sidenavOpen
  //   });
  // };

  getNavbarTheme = () => {
    return "dark";
    // this.props.location.pathname.indexOf(
    //   "admin/alternative-dashboard"
    // ) === -1
    //   ? 
    // "dark"
    // : 
    // "light"
    // ;
  };

  render() {
    return (
      this.props.location.pathname.split("/").filter((pathItem, pathItemIndex) => pathItemIndex > 0).includes("")
        ?
        "Error: URL not valid"
        :
        (
          <>
            {/* <Sidebar
                {...this.props}
                toggleSidenav={this.toggleSidenav}
                sidenavOpen={this.state.sidenavOpen}
                logo={{
                  innerLink: "/",
                  imgSrc: logoBlue,
                  imgAlt: "..."
                }}
              /> */}
            <div
              className="main-content"
              ref="mainContent"
              onClick={this.closeSidenav}
            >
              <NormalNavbar
                {...this.props}
                theme={this.getNavbarTheme()}
                toggleSidenav={this.toggleSidenav}
              // brandText={this.getBrandText(this.props.location.pathname)}
              />

              {/* <CusTable {...this.props} path={this.props.location.state ? 
                                                this.props.location.state.path ? this.props.location.state.path : "" 
                                                : ""}/> */}
              {
                this.props.location.state
                  ?
                  <CusTableFSR {...this.props} />
                  :
                  this.state.data == null
                    ?
                    <Loading />
                    :
                    this.state.data.length == 0
                      ?
                      <Loading />
                      :
                      this.state.data.filter(pathRow => pathRow['key'] == this.props.location.pathname.split("/").slice(2).join("/")).length == 0
                        ?
                        <CusTable {...this.props} path={this.props.location.pathname.split("/").slice(2).join("/")} data={this.state.data} genData={this.genData.bind(this)} />
                        :
                        this.state.data.filter(pathRow => pathRow['key'] == this.props.location.pathname.split("/").slice(2).join("/"))[0].size == 0
                          ?
                          <CusTable {...this.props} path={this.props.location.pathname.split("/").slice(2).join("/")} data={this.state.data} genData={this.genData.bind(this)} />
                          :
                          <CusFileViewer {...this.props} path={this.props.location.pathname.split("/").slice(2).join("/")} data={this.state.data} genData={this.genData.bind(this)} />
              }
              <FileFooter />
            </div>
          </>
        )
    )
  }
}

export default Main;