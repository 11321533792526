import React from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  FaFile, FaFolder, FaTrash,
  FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint,
  FaFileMedicalAlt, FaFileCsv, FaDownload, FaFileSignature,
  FaFileAlt, FaFileArchive, FaFileImage, FaFileImport, FaFileCode
} from "react-icons/fa";// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import ResultHeader from "components/Headers/ResultHeader.js";
import { listFiles, getExtensionByFileName, searchByKeyword, uploadFile } from "Controller/File.js"
import { dataTable } from "dummyData/CusTableDefault";
import './CusTable.scss'
import Dropzone from "react-dropzone";

import _ from 'lodash';

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});
const fileExtTypeMap = [
  {
    type: 'image',
    ext: ['png', 'jpg', 'jpeg']
  },
  {
    type: 'pdf',
    ext: ['pdf']
  },
  {
    type: 'word',
    ext: ['doc', 'docx']
  },
  {
    type: 'excel',
    ext: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls']
  },
  {
    type: 'csv',
    ext: ['csv']
  },
  {
    type: 'ppt',
    ext: ['ppsx', 'ppt', 'pptm', 'pptx']
  },
  {
    type: 'media',
    ext: ['mp3', 'wav', 'mp4', 'mov', 'avi', 'flv']
  },
  {
    type: 'txt',
    ext: ['txt']
  },
  {
    type: 'zip',
    ext: ['zip', '7z']
  },
  {
    type: 'html',
    ext: ['htm', 'html']
  },
  {
    type: 'folder',
    ext: ['NotFound']
  }
]

const { SearchBar } = Search;
class CusTablesFSR extends React.Component {
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      searchResult: []
      // currentFolder:listFiles({path: this.props.location.pathname}).files,

    };
  }
  componentDidMount(e) {
    searchByKeyword(this.props.location.state.keyword).then(result => {
      this.setState({ searchResult: result });
    });
  }
  onDrop = (acceptedFiles) => {
    console.log(JSON.stringify(acceptedFiles));
  }
  checkIfAny = (filename, extList) => {
    return extList.includes(getExtensionByFileName(filename));
  }
  imgGetter = (path) => {
    if (path == "/main/0.jpg")
      return "https://cdn.hk01.com/di/media/images/3841315/org/44ab0f3b45047ad890648f9600fe8f28.png/Oyt8V9Yk9zp1_31KxuZa1xI1wavORri5FfXIBRX1yAU?v=w1920";
    if (path == "/main/1.PNG")
      return "https://i.redd.it/014mifuhemm41.jpg";
  }
  dateFormatter(cell) {
    return (<span> {cell} </span>);
  }
  componentWillReceiveProps(nextProps) {
    searchByKeyword(nextProps.location.state.keyword).then(result => {
      this.setState({ searchResult: result });
    });
  }
  copyToClipboardAsTable = el => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      )
    });
  };
  dateFormatter(cell) {
    return (<span> {new Date(cell).toString()} </span>);
  }

  switchFileIcons(fileName) {
    //input is row['fileName'] already

    let filExtWithDot = getExtensionByFileName(fileName)

    let ext = filExtWithDot === "NotFound" ? filExtWithDot : filExtWithDot.split('.').pop()

    let fileTypeMap = _.find(fileExtTypeMap, function (o) {
      return (o.ext).indexOf(ext) > -1
    })

    let fileType = fileTypeMap.type

    console.log(fileType);

    switch (fileType) {
      case "folder":
        return <FaFolder className="folder fileIcon" />
      case "image":
        return <FaFileImage className="image fileIcon" />
      case "pdf":
        return <FaFilePdf className="pdf fileIcon" />
      case "word":
        return <FaFileWord className="word fileIcon" />
      case "excel":
        return <FaFileExcel className="excel fileIcon" />
      case "ppt":
        return <FaFilePowerpoint className="ppt fileIcon" />
      case "csv":
        return <FaFileCsv className="csv fileIcon" />
      case "media":
        return <FaFileMedicalAlt className="alt fileIcon" />
      case "txt":
        return <FaFileAlt className="txt fileIcon" />
      case "zip":
        return <FaFileArchive className="zip fileIcon" />
      case "html":
        return <FaFileCode className="fileIcon" />
      default:
        return <FaFile className="fileIcon" />
    }
  }

  render() {
    return (
      <>
        {this.state.alert}
        <ResultHeader keyword={this.props.location.state.keyword} {...this.props} />
        <Container className="mt--6" fluid>
          <Row >
            <div className="col">
              <Card>
                {/* <CardHeader>
                  <h3 className="mb-0">React Bootstrap Table 2</h3>
                  <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p>
                </CardHeader> */}
                <ToolkitProvider
                  data={this.state.searchResult}
                  keyField="filePath"
                  columns={
                    [{
                      dataField: "fileName",
                      text: "fileName",
                      sort: true,
                      formatter: (cell, row) => {
                        //folder icon
                        return (
                          <div className="cellStyle">
                            {this.switchFileIcons(row['fileName'])}{" "}
                            <span className="cellVerAlign-middle">{cell} </span>
                          </div>
                        );
                      }
                    },
                    // {
                    //   dataField: "type",
                    //   text: "type",
                    //   sort: true
                    // },
                    {
                      dataField: "lastModified",
                      text: "upload time",
                      sort: true,
                      formatter: this.dateFormatter,
                      classes: 'cellVerAlign-middle'
                    },
                    {
                      dataField: "filePath",
                      text: "filePath",
                      sort: true,
                      classes: 'cellVerAlign-middle'
                    },
                    ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        rowStyle={{ lineHeight: "6px" }}
                        hover
                        rowEvents={
                          {
                            onDoubleClick: (e, row, rowIndex) => {
                              this.props.history.push({
                                pathname: row['filePath'][0] == "/" ? `/main${row['filePath']}` : `/main/${row['filePath']}`
                                //   // state: { path: this.props.path == "" ? row.fileName : this.props.path+"/"+row.fileName }
                              });
                            }
                          }
                        }
                      />
                    </div>
                  )}
                </ToolkitProvider>
                <div id="contextmenu" style={{ display: "none", position: "fixed", background: "gray", border: "5px solid black" }}>
                  <img id="imgContext" width="200" />
                </div>
              </Card>
              {/* <Card>
                <CardHeader>
                  <h3 className="mb-0">Action buttons</h3>
                  <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p>
                </CardHeader>
                <ToolkitProvider
                  data={dataTable}
                  keyField="name"
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true
                    },
                    {
                      dataField: "position",
                      text: "Position",
                      sort: true
                    },
                    {
                      dataField: "office",
                      text: "Office",
                      sort: true
                    },
                    {
                      dataField: "age",
                      text: "Age",
                      sort: true
                    },
                    {
                      dataField: "start_date",
                      text: "Start date",
                      sort: true
                    },
                    {
                      dataField: "salary",
                      text: "Salary",
                      sort: true
                    }
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <ButtonGroup>
                              <Button
                                className="buttons-copy buttons-html5"
                                color="default"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  this.copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="default"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => this.componentRef}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>
                          </Col>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1 float-right"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={el => (this.componentRef = el)}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card> */}
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default CusTablesFSR;
