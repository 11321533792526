
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  NavbarBrand
} from "reactstrap";
import logo from 'assets/img/brand/mesh-drive-logo.png';
import { Auth } from 'aws-amplify';
import { checkProfileImage, fileContent } from 'Controller/File.js'

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "", name: "", profileImg: require("assets/img/profile.png").default }
    this.goToMain = this.goToMain.bind(this)
    this.goToSettings = this.goToSettings.bind(this)
    this.goToProfile = this.goToProfile.bind(this)
  }
  // function that on mobile devices makes the search open
  goToMain() {
    this.props.history.push("/main");
  }
  goToProfile() {
    this.props.history.push("/profile");
  }
  goToSettings() {
    this.props.history.push("/settings");
  }
  openSearch = () => {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  };
  // function that on mobile devices makes the search close
  closeSearch = () => {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  };
  startSearch = (e) => {
    const { history } = this.props;
    if (e.key === 'Enter') {
      if (e.target.value != "") {
        history.push({
          pathname: '/main',
          state: { keyword: e.target.value }
        });
      } else {
        history.push({
          pathname: '/main'
        });
      }
      e.preventDefault();
    }
  }
  async signOut() {
    try {
      await Auth.signOut();
      this.props.history.push({
        pathname: "/auth/login"
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  async setUserName() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      this.setState({ email: user.attributes.email, name: user.attributes.name });
    } catch (error) {
      alert(error)
    }
  }
  componentDidMount() {
    this.setUserName();
    checkProfileImage(imageJSON => {
      if (imageJSON != null) {
        fileContent(imageJSON['key']).then(url => {
          this.setState({ profileImg: url });
        })
      }
    })
  }
  render() {
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom",
            { "navbar-dark bg-info": this.props.theme === "dark" },
            { "navbar-light bg-secondary": this.props.theme === "light" }
          )}
        >
          <a onClick={this.goToMain} className="ml-5" style={{ cursor: "pointer", width: "200px" }}>
            {/* <img
              width="200px"
              alt="..."
              src={logo}
            /> */}
            <div className="" style={{ fontSize: "20px", color: "white", fontWeight: "bold"}}>UIGCL Drive</div>
          </a>

          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Form
                className={classnames(
                  "navbar-search form-inline mr-sm-3",
                  { "navbar-search-light": this.props.theme === "dark" },
                  { "navbar-search-dark": this.props.theme === "light" }
                )}
              >


                <FormGroup className="mb-0">


                  <InputGroup className="input-group-alternative input-group-merge">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Search all folders and files..." type="text" onKeyDown={this.startSearch} />
                  </InputGroup>
                </FormGroup>
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={this.closeSearch}
                >
                  <h4 aria-hidden={true}>×</h4>
                </button>
              </Form>

              <Nav className="align-items-center ml-md-auto" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    {/* <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div> */}
                  </div>
                </NavItem>
                <NavItem className="d-sm-none">
                  <NavLink onClick={this.openSearch}>
                    <i className="ni ni-zoom-split-in" />
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto ml-md-0" style={{ cursor: "pointer" }} navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <h4 className="avatar avatar-sm rounded-circle" style={{ margin: "auto" ,backgroundColor:"#fff" }}>
                        <img
                          alt="..."
                          src={this.state.profileImg}
                          style={{ width: "35px", height: "35px" }}
                        />
                      </h4>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {this.state.name == null ? "" : <>{this.state.name}<br /></>}
                          {this.state.email}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h4 className="text-overflow m-0">Welcome!  歡迎!</h4>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem>
                      <a onClick={()=>{ this.props.history.push("/videochat")}}>
                        <h4>Video Conference 視像會議</h4>
                      </a>
                    </DropdownItem>

                    <DropdownItem divider />
                    <DropdownItem>
                      <a onClick={this.goToProfile}>
                        <h4>My profile  個人資科</h4>
                      </a>
                    </DropdownItem>
                    <DropdownItem >
                      <a onClick={this.goToSettings}>
                        <h4>Settings  設定</h4>
                      </a>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem
                    >
                      <a onClick={this.signOut.bind(this)} className="px-0 py-0" >
                        <h4>Logout  登出</h4>
                      </a>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => { },
  sidenavOpen: false,
  theme: "dark"
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default AdminNavbar;
