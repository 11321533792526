const Files=[
    {fileName: "main",      type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
        { 
            fileName: "0.jpg",
            type: "image/jpg", 
            createTime: (new Date()).toString(), 
            size: "1kb",
            deleted: false, 
            children: null 
        },
        {fileName: "half.MP4",           type: "video/mp4", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
        {fileName: "1.PNG",           type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
        {fileName: "2.txt",           type: "text",      createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
        {fileName: "3",               type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
            {fileName: "3-0",             type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
                {fileName: "3-0-0.jpg",       type: "image/jpg", createTime: (new Date()).toString(), size: "1kb"        ,deleted: false, children: null },
                {fileName: "3-0-1.png",       type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
                {fileName: "3-3-2.txt",       type: "text",      createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
            ] },
        ] },
        {fileName: "4",               type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
            {fileName: "4-0.jpg",         type: "image/jpg", createTime: (new Date()).toString(), size: "1kb"        ,deleted: false, children: null },
            {fileName: "4-1.png",         type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
            {fileName: "4-2",             type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
                {fileName: "4-2-0.jpg",       type: "image/jpg", createTime: (new Date()).toString(), size: "1kb"        ,deleted: false, children: null },
                {fileName: "4-2-1.png",       type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
            ] },
        ] },
        {fileName: "5",               type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
            {fileName: "5-0.jpg",         type: "image/jpg", createTime: (new Date()).toString(), size: "1kb"        ,deleted: false, children: null },
            {fileName: "5-1.png",         type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
            {fileName: "5-2.txt",         type: "text",      createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
            {fileName: "5-3",             type: "folder",    createTime: (new Date()).toString(), size: null         ,deleted: false, children: [
                {fileName: "5-3-0.jpg",       type: "image/jpg", createTime: (new Date()).toString(), size: "1kb"        ,deleted: false, children: null },
                {fileName: "5-3-1.png",       type: "image/png", createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
                {fileName: "5-3-2",           type: "folder",      createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: [
                { fileName: "5-3-2-a.png", type: "image/png", createTime: (new Date()).toString(), size: "256kb", deleted: false, children: null }
                ] },
            ] },
        ] },
        {fileName: "6.pdf",           type: "pdf",       createTime: (new Date()).toString(), size: "256kb"      ,deleted: false, children: null },
    ] }]

export default Files;