import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import NormalNavbar from "components/Navbars/NormalNavbar.js";
import Dropzone from "react-dropzone";
import './Settings.scss'
import { Auth } from 'aws-amplify';
import { uploadFile } from "Controller/File.js";
import { checkProfileImage, fileContent } from 'Controller/File.js'
import { deleteFile } from "Controller/File";
import Countries  from 'react-select-country';
class Profile extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      error: null,
      errorMsg: null,
      verificationCode: '',
      disabled: true,
      showDetail: false,
      picture: null,
      info: {
        name: '',
        phone_number: '',
        email: '',
        address: '',
        "custom:country": '',
        "custom:city": ''
      },
      uploading: false,
      uploadmessage: "Release here\n\n放開至此處"
    }
    // this.onSelectCountry = this.onSelectCountry.bind(this);
    this.userInfo = this.userInfo.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onFormValueChange = this.onFormValueChange.bind(this)
    this.handleEditing = this.handleEditing.bind(this)
    this.forgotPassword = this.forgotPassword.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }
  // onSelectCountry(event) {
  //   this.state.selectedCountry = {
  //     id: event.target.value,
  //     name: event.target.options[event.target.selectedIndex].text
  //   }
  //   this.state.selectedCountry = this.refs.country.selected;
  // }
  setProgress = (progress) => {
    this.setState({
      uploading: true,
      uploadmessage: `Uploaded: ${progress.loaded}/${progress.total}\n\n更新中`,
      profileImg: require("assets/img/profile.png").default
    });
  }
  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles)
    if (acceptedFiles.length == 1) {
      if ([".jpg", ".png"].some(ext => ext == acceptedFiles[0]['path'].slice(-4))) {
        checkProfileImage(imageJSON => {
          if (imageJSON != null) {
            deleteFile(imageJSON['key'], { level: "private" }).then().catch(err => alert(err))
          }
          uploadFile(`profile/profile${acceptedFiles[0]['path'].slice(-4)}`, acceptedFiles[0], {
            level: 'private',
            progressCallback: this.setProgress.bind(this)
          })
            .then(result => {
              this.setState({
                uploading: false,
                uploadmessage: "Release here\n\n放開至此處"
              })
              window.location.href = window.location.href;
            })
            .catch(err => console.log(err));

        });
      } else {
        alert("File type not valid, it must be .jpg or .png!\n文件類型不合規, 必須是.jpg或.png! ")
      }
    }
    else {
      alert("Only allow single file upload! 祇准許上載一個文件!")
    }
  }

  async forgotPassword(e) {
    e.preventDefault()
    try {
      await Auth.forgotPassword(this.state.info.email);
      alert("A confirmation code is sent to your email!\n驗証碼已發送至你的電子郵箱!");
      this.resetPassword();
      /* Once the user successfully confirms their account, update form state to show the sign in form*/
    }
    catch (err) {
      console.log({ err });
      this.setState({
        error: err,
        errorMsg: err.message
      })
    }
  }

  resetPassword() {
    this.props.history.push({
      pathname: "/auth/resetPassword",
      state: { email: this.state.info.email }
    });
  }

  handleEditing(event) {
    this.setState({
      editData: {
        ...this.state, [event.target.name]: event.target.value
      }
    });
  }

  handleTagsinput = tagsinput => {
    this.setState({ tagsinput });
  };
  handleReactQuillChange = value => {
    this.setState({
      reactQuillText: value
    });
  };
  handleReactDatetimeChange = (who, date) => {
    if (
      this.state.startDate &&
      who === "endDate" &&
      new Date(this.state.startDate._d + "") > new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else if (
      this.state.endDate &&
      who === "startDate" &&
      new Date(this.state.endDate._d + "") < new Date(date._d + "")
    ) {
      this.setState({
        startDate: date,
        endDate: date
      });
    } else {
      this.setState({
        [who]: date
      });
    }
  };
  async getUserInfo() {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ info: user.attributes });
  }

  componentDidMount() {
    this.getUserInfo();
    checkProfileImage(imageJSON => {
      if (imageJSON != null) {
        fileContent(imageJSON['key']).then(url => {
          this.setState({ profileImg: url });
        })
      }
    })
  }

  async userInfo() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(
        user, this.state.info
      );
      window.location.href = window.location.href;
    } catch (err) {
      if (err.message != null) {
        alert(err.message);
      }
    }
  }

  onChange(e) {
    if (e.target.value != null)
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    else
      this.setState({ ...this.state, [e.target.name]: "" });
  }

  onFormValueChange(e) {
    console.log(e.target.name);
    console.log(e.target.value)
    this.setState({ ...this.state, "info": { [e.target.name]: e.target.value } });
  }

  handleInputClik() {
    this.setState({ disabled: !this.state.disabled })

  }

  render() {
    return (
      <div style={{ minHeight: "10%" }}>
        <span className="mask" style={{ height: "30%", backgroundColor: "#11cdef" }} />
        <NormalNavbar
          {...this.props}
        // brandText={this.getBrandText(this.props.location.pathname)}
        />
        <div className="settings-container row justify-content-center" >
          <Card className="main-card">

            <Form>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h2 className="mb-0">Profile</h2>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      onClick={this.handleInputClik.bind(this)}
                      size="sm"
                    >
                      Edit
                  </Button>

                    <Button
                      color="success"
                      size="sm"
                      onClick={this.userInfo}
                    >
                      Save
                  </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  User information 用户資料
                  </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-email"
                        >
                          Email address 電郵地址
                          </label>
                        <Input
                          id="input-email"
                          placeholder="jesse@example.com"
                          name="email"
                          value={this.state.info.email}
                          onChange={this.onFormValueChange}
                          type="email"
                          disabled="true"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-Companyname"
                        >
                          Company name 公司名稱
                        </label>
                        <Input
                          id="name"
                          placeholder="Company name"
                          type="text"
                          name="name"
                          value={this.state.info.name}
                          disabled={(this.state.disabled) ? "disabled" : ""}
                          onChange={this.onFormValueChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Phone 電話號碼
                        </label>
                        <Input
                          id="phone_number"
                          placeholder="Phone"
                          type="phone"
                          name="phone_number"
                          value={this.state.info.phone_number}
                          onChange={this.onFormValueChange}
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-password"
                        >
                          Password 密碼:  ******
                        </label>

                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <a
                          className="edit-password"
                          onClick={this.forgotPassword}
                          style={{ cursor: "pointer" }}
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        >
                          Reset Password 重設密碼
                        </a>
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* 
                  {
                    this.state.showDetail ?
                      <Row className="align-items-center">
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Current:
                          </label>

                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              id="input-current"
                              placeholder="current"
                              type="current"
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      :
                      null
                  }
                  {
                    this.state.showDetail ?
                      <Row className="align-items-center">
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              New Password:
                          </label>

                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              id="input-NewPassword"
                              placeholder="New Password"
                              type="NewPassword"
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      :
                      null
                  }
                  {
                    this.state.showDetail ?
                      <Row className="align-items-center">
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Confirm:
                          </label>

                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Input
                              id="input-Confirm"
                              placeholder="Confirm"
                              type="Confirm"
                              disabled={(this.state.disabled) ? "disabled" : ""}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      :
                      null
                  } */}

                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Address 地址
                        </label>
                        <Input
                          id="input-address"
                          placeholder="Home Address"
                          name="address"
                          value={this.state.info.address}
                          type="text"
                          onChange={this.onFormValueChange}
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>

                      <Col lg="7">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            Country 國家
                        </label>
                          {/* <Input
                            id="country"
                            placeholder="Country name"
                            type="text"
                            name="country"
                            value={this.state.info.country}
                            disabled={(this.state.disabled) ? "disabled" : ""}
                            onChange={this.onFormValueChange}
                          /> */}
                          <Countries 
                          ref="custom:country"
                          disabled={(this.state.disabled) ? "disabled" : ""}
                          name="custom:country" 
                           className="mr-5"
                          style={{ padding:"10px 0px" }}
                          value={this.state.info['custom:country']}
                          empty="Country name" onChange={(e) => this.onFormValueChange(e)} />
                          
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                          >
                            City 城巿
                        </label>
                          <Input
                            id="custom:city"
                            placeholder="City name"
                            type="text"
                            name="custom:city"
                            value={this.state.info['custom:city']}
                            onChange={this.onFormValueChange}
                            disabled={(this.state.disabled) ? "disabled" : ""}
                          />
                        </FormGroup>
                      </Col>
                  </Row>
                    {/* <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-city"
                        >
                          City
                        </label>
                        <Input
                          id="input-city"
                          placeholder="City"
                          type="text"
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Country
                        </label>
                        <Input
                          id="input-country"
                          placeholder="Country"
                          type="text"
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Postal code
                        </label>
                        <Input
                          id="input-postal-code"
                          placeholder="Postal code"
                          type="number"
                          disabled={(this.state.disabled) ? "disabled" : ""}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                    <div>
                      <label
                        className="form-control-label"
                        htmlFor="input-country"
                      >
                        Company Image 公司圖片
                        </label>
                      <Card>
                        <div className="dropzone background">
                          <Dropzone
                            onDrop={this.onDrop.bind(this)}
                            name="picture"
                            disabled={(this.state.disabled) ? "disabled" : ""}
                            value={this.state.picture}>
                            {
                              (dragProps) => (
                                <section>
                                  <div  {...dragProps.getRootProps()}>
                                    <input  {...dragProps.getInputProps()} />
                                    <p className="align-self-center justify-content-center" style={{ textAlign: 'center' }}>
                                      {dragProps.isDragActive || this.state.uploading ?
                                        <>{this.state.uploadmessage.split("\n").map(word => <>{word}<br /></>)}</>
                                        :
                                        <><div>Drag and drop an image file here</div>
                                          <br />
                                          <div>請將公司圖片拖曳到這裡</div>
                                        </>
                                      }
                                    </p>
                                  </div>
                                </section>
                              )
                            }
                          </Dropzone>
                        </div>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          <br />Current image 目前圖片</label>

                        <img
                          alt="..."
                          src={this.state.profileImg}
                          width="100%"
                        />
                      </Card>
                    </div>
                </div>
              </CardBody>
            </Form>
          </Card>
        </div>
        </div>
    )
  }
}

export default Profile;