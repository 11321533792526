import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    FormText
} from "reactstrap";
import { useHistory } from "react-router-dom";
import gitLogo from "assets/img/icons/common/github.svg"
import googleLogo from "assets/img/icons/common/google.svg"
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Auth } from 'aws-amplify';

import "./Register.scss"
import { SUCCESS } from "dropzone";




/* Confirm sign up function for MFA */

class ConfirmSignUp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            errorMsg: null,
            username: this.props.match.params.name ? this.props.match.params.name : this.props.location.state ? this.props.location.state.username : "",
            verificationCode: this.props.match.params.code ? this.props.match.params.code : '',
            resended: null
        }
        this.onChange = this.onChange.bind(this)
        this.backToSignUp = this.backToSignUp.bind(this)
        this.confirmed = this.confirmed.bind(this)
        this.confirmSignUp = this.confirmSignUp.bind(this)
        this.resendSignUp = this.resendSignUp.bind(this)
    }
    onChange(e) {
        this.setState({ ...this.setState, [e.target.name]: e.target.value });
    }

    backToSignUp() {
        this.props.history.push("/auth/register");
    }

    confirmed() {
        this.props.history.push({
            pathname: "/auth/login",
            state: { username: this.props.location.state.username }
        });
    }

    handleError(err) {
        //input is row['fileName'] already

        console.log(err.message);
        //   if (fileType == "folder" && !isfolder)
        //     return <FaFile className="fileIcon" />;

        switch (err.message) {
            case "Username cannot be empty":
                this.setState({ errorMsg: "Username cannot be empty \n 用户名稱不能為空值" });
                break;
            case "Custom auth lambda trigger is not configured for the user pool.":
                this.setState({ errorMsg: "Please enter your password \n 請輸入密碼" });
                break;
            case "Username/client id combination not found.":
                this.setState({ errorMsg: "Username is not find \n 用戶名不存在" });
                break;
            case "Username/client id combination not found.":
                this.setState({ errorMsg: "Username is not find \n 用戶名不存在" });
                break;
            case "Confirmation code cannot be empty":
                this.setState({ errorMsg: "Verification code cannot be empty \n 驗證碼不能為空值" });
                break;
            case "Incorrect username or password.":
                this.setState({ errorMsg: "Incorrect username or password \n 用戶名或密碼錯誤" });
                break;
            case "User cannot be confirmed. Current status is CONFIRMED":
                this.setState({ errorMsg: "User cannot be confirmed  \n 無法確認用戶" });
                break;
            case "Invalid verification code provided, please try again.":
                this.setState({ errorMsg: "Invalid verification code provided \n 提供的驗證碼無效" });
                break;
            default:
                this.setState({
                    error: err,
                    errorMsg: err.message,
                    resended: null
                })
        }
    }

    async confirmSignUp(e) {
        e.preventDefault()
        try {
            await Auth.confirmSignUp(this.state.username, this.state.verificationCode);
            this.confirmed();
            /* Once the user successfully confirms their account, update form state to show the sign in form*/
        } catch (err) {
            this.handleError(err);
        }
    }


    async resendSignUp(e) {
        e.preventDefault()
        try {
            await Auth.resendSignUp(this.state.username);
            // alert("Just resent the verification code to your email.");
            this.setState({
                error: null,
                errorMsg: null,
                resended: 'Just resent the verification code to your email.'
            })

            /* Once the user successfully confirms their account, update form state to show the sign in form*/
        } catch (err) {
            this.handleError(err);
        }
    }

    render() {
        return (
            <>
                <AuthHeader
                    title="Create an account"
                    lead="Use these awesome forms to login or create new account in your project for free."
                />
                <Container className="mt--8 pb-5">
                    <Row className="justify-content-center">
                        <Col lg="6" md="8">
                            <Card className="bg-secondary border-0">

                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-left text-muted mb-4">
                                        <h3>Confirm Sign up 確認註冊</h3>
                                    </div>
                                    <Form role="form">

                                        {(!this.props.match.params.name && !this.props.match.params.code) ? <>
                                            {(!this.props.location.state) &&
                                                <FormGroup>
                                                    <div className="ml-2">
                                                        <h5>Email 電郵地址</h5>
                                                    </div>
                                                    <InputGroup className="input-group-merge input-group-alternative mb-3">
                                                        <InputGroupAddon addonType="prepend">
                                                            <InputGroupText>
                                                                <i className="ni ni-circle-08" />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            defaultValue={this.props.location.state ? this.props.location.state.username : ""}
                                                            name="username"
                                                            onChange={this.onChange}
                                                            placeholder="Example: test@test.com"
                                                            type="text" />
                                                    </InputGroup>
                                                </FormGroup>
                                            }
                                            <div className="ml-2">
                                                <h5>Verification Code 驗證碼</h5>
                                            </div>
                                            <FormGroup>
                                                <InputGroup className="input-group-merge input-group-alternative mb-3">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <i className="ni ni-key-25" />
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Enter your code"
                                                        type="text"
                                                        name="verificationCode"
                                                        onChange={this.onChange}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                            {
                                                this.state.errorMsg ?
                                                    <FormText color="danger">{this.state.errorMsg.split("\n").map(x => <>{x}<br /></>)}</FormText> :
                                                    null
                                            }
                                            {
                                                this.state.resended ?
                                                    <FormText color="success">{this.state.resended}</FormText> :
                                                    null
                                            }
                                            <small className="text-muted">lost your code?  </small>
                                            <a className="text-left ml-2 text-primary" type="button" onClick={this.resendSignUp}>
                                                <small>Resand Code</small>
                                            </a></>
                                            :
                                            <>Click confirm the activate your account<br /><br /></>
                                        }
                                        <Row className="align-items-center">

                                            <Col>
                                                <Button className="mt-2 btn px-5 float-right " color="info" type="button" onClick={this.confirmSignUp}>
                                                    Confirm
                                                    <br />
                                                    確認
                                                </Button>
                                                <Button className="mt-2 btn px-3 mr-2 float-right " color="secondary" type="button" onClick={this.backToSignUp}>
                                                    Back to sign up
                                                    <br />
                                                    返回註冊
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
export default ConfirmSignUp;
