import { Tree } from "antd";
import React from "react";
// import 'antd/dist/antd.css';
import { listFiles, convertFilesToAntdTree } from "Controller/File.js";
class CusTree extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: props.folderOnly
        ?
        convertFilesToAntdTree(listFiles({ path: "" }).files, "").filter(x => x.isLeaf == null)
        :
        convertFilesToAntdTree(listFiles({ path: "" }).files, "")
    };
  }
  handlePageJump(selectedKeys, e) {
    if (selectedKeys.length != 0) {
      console.log(selectedKeys[0]);
      this.props.history.push({
        pathname: "/" + selectedKeys[0]
      });
    }
  }
  onLoadData({ key, children }) {
    return new Promise(resolve => {
      if (children) {
        resolve();
        return;
      }
      setTimeout(() => {
        let treeData = this.updateTreeData(this.state.treeData, key,
          this.props.folderOnly ? convertFilesToAntdTree(listFiles({ path: key }).files, key + "/").filter(x => x.isLeaf == null) :
            convertFilesToAntdTree(listFiles({ path: key }).files, key + "/"));
        this.setState({ treeData });
        resolve();
      }, 1000);
    });
  }
  updateTreeData(list, key, children) {
    return list.map(node => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      } else if (node.children) {
        return {
          ...node,
          children: this.updateTreeData(node.children, key, children),
        };
      }
      return node;
    });
  }
  render() {
    const loop = data =>
      data.map(item => {
        const title = (<span>{item.title}</span>)
        if (item.children) {
          return { title, key: item.key, children: loop(item.children) };
        }
        return {
          title,
          key: item.key,
        };
      });
    return (
      <Tree
        showIcon
        {...this.props}
        loadData={this.onLoadData.bind(this)}
        style={{ backgroundColor: "white" }}
        treeData={this.state.treeData}
        onSelect={this.handlePageJump.bind(this)}
      />);
  }
}

export default CusTree;