import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";
import NormalNavbar from "components/Navbars/NormalNavbar.js";
import { Auth } from 'aws-amplify';
import './Settings.scss'


class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null,
      errorMsg: null,
      verificationCode: '',
      disabled: true,
      showDetail: false,
      picture: null,
      info: {
        name: '',
        phone_number: '',
        email: '',
        address: '',
        notifications: ''
      }
    }
  }

  componentDidMount() {
    this.getUserInfo();
  }

  async userInfo() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(
        user, this.state.info
      );
      window.location.href = window.location.href;
    } catch (error) {
      alert(error);
    }
  }

  onChange(e) {
    if (e.target.value != null)
      this.setState({ ...this.state, [e.target.name]: e.target.value });
    else
      this.setState({ ...this.state, [e.target.name]: "" });
  }

  onFormValueChange(e) {
    this.setState({ ...this.state, "info": { [e.target.name]: e.target.value } });
  }

  async getUserInfo() {
    let user = await Auth.currentAuthenticatedUser();
    this.setState({ info: user.attributes });
  }

  render() {
    return (
      <div
        className=""
        style={{
          minHeight: "10%",
        }}
      >
        <span className="mask" style={{ height: "30%", backgroundColor: "#11cdef" }} />
        <NormalNavbar
          {...this.props}
        // brandText={this.getBrandText(this.props.location.pathname)}
        />
        <div className="settings-container row justify-content-center" >
          <Card className="main-card">
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h2 className="mb-0">Setting</h2>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <h3 >Notifications</h3>
                <h5 className=" text-muted mb-4">
                  We'll always let you know about important changes.
                </h5>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="8">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          By email
                        </label>
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label className="custom-toggle custom-toggle-info mr-1">
                          <input
                            defaultChecked
                            type="checkbox"
                          />
                          <span
                            className="custom-toggle-slider rounded-circle"
                            data-label-off="No"
                            data-label-on="Yes"
                            name="notifications"
                            value={this.state.info.notifications}
                            onChange={this.onFormValueChange}
                          />
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default Settings;