import DefaultFiles from "dummyData/FilesData.js";
import { FaFolder, FaFile } from "react-icons/fa";
import code from "assets/img/code.pdf";
import cat from "assets/img/cat.jfif";
import piano from "assets/img/piano.mp4"
import notexist from "assets/img/notexist.png";
import { Storage } from "aws-amplify";

export function listFiles({ path }) {
    return Storage.list(path, { level: 'private' });
}



export function listName(path, filelist) {
    let subFileList = filelist.filter(file => {
        if (path === "") {
            return true
        } else {
            return file['key'].slice(0, path.length) == path
        }
    });
    let listForAvoidDuplicate = subFileList.map(file => {
        let name = "";
        if (path === "") {
            name = file['key'].split("/")[0];
        } else {
            name = file['key'].slice(path.length + 1).split("/")[0];
        }
        return name;
    });

    return subFileList.map((file, pos) => {
        let name = "";
        if (path === "") {
            name = file['key'].split("/")[0];
        } else {
            if (path[path.length-1] == "/")
                name = file['key'].slice(path.length).split("/")[0];
            else
                name = file['key'].slice(path.length + 1).split("/")[0];
        }
        console.log("sublist" + file['fileName']);
        file['fileName'] = name;
        return file;
    }).filter(function (item, pos) {
        let name = "";
        if (path === "") {
            name = item['key'].split("/")[0];
        } else {
            if (path[path.length-1] == "/")
                name = item['key'].slice(path.length).split("/")[0];
            else
                name = item['key'].slice(path.length + 1).split("/")[0];
        }
        return listForAvoidDuplicate.indexOf(name) == pos;
    }).map(file => {
        file['isFolder'] = false;
        if (getNameFormPath(file.key) != file.fileName || file.size == 0) {
            file['isFolder'] = true;
        }
        file['filePath'] = `${path}/${file.fileName}`;
        return file;
    });
}

export async function errorChecker(promise) {
    const v = null;
    try {
        return await promise;
    } catch (error) {
        // "TypeError: Cannot read property 'thisWillThrow' of null"
        return error.message;
    }
}

export function processStorageList(results) {
    const filesystem = {}
    // https://stackoverflow.com/questions/44759750/how-can-i-create-a-nested-object-representation-of-a-folder-structure
    const add = (source, target, item) => {
        const elements = source.split("/");
        const element = elements.shift();
        if (!element) return // blank
        target[element] = target[element] || { __data: item }// element;
        if (elements.length) {
            target[element] = typeof target[element] === "object" ? target[element] : {};
            add(elements.join("/"), target[element], item);
        }
    };
    results.forEach(item => add(item.key, filesystem, item));
    return filesystem
}

export function fileContent(key) {
    return Storage.get(key, { level: "private" });
}



export function download(key) {
    Storage.get(key, { level: "private", download: true });
}

export function fileTypeAttrGetter(file) { //get Type Attribute, the effect will be the same if AWS implemented 
    if (file.type == "image/jpg")
        return "jpg";
    if (file.type == "video/mp4")
        return "mp4";
    if (file.type == "pdf")
        return "pdf";
    return "png";//default show "i am not exist"
}

export function deleteFile(path, config) {
    //path can be another condition
    return Storage.remove(path, config);
}

export function uploadFile(path, file, config) {
    //wait for implement
    return Storage.put(path, file, config);
}

export function searchByKeyword(keyword) {
    //keyword can be name/date/type etc
    return searchByKeywordInsideFolderRecu("", keyword);
}

export async function searchByKeywordInsideFolderRecu(folderpath, keyword) {
    //folderpath can be another identifier 
    //this function will search keyword inside folderpath recursively
    //maybe not avaliable in AWS, if so, searchByKeyword will fully rewrite
    //keyword can be name/date/file type etc

    let fileRecord = await listFiles({ path: folderpath });
    let list = [];
    const search = (fpath, kword) => {
        listName(fpath, fileRecord).filter(file => file.fileName.includes(kword))
            .forEach(file => {
                console.log("result:" + fpath + "/" + file.fileName)
                console.log(file)
                list.push({ ...file });
            });
        listName(fpath, fileRecord).filter(file => file.isFolder).forEach(file => {
            if (fpath === "") {
                console.log("folder" + file.fileName)
                search(file.fileName, keyword);
            } else {
                console.log("folder!" + fpath + file.fileName)
                search(fpath + "/" + file.fileName, keyword);
            }
        });
    }
    search(folderpath, keyword);
    return list;
}

export function checkProfileImage(callback) {
  listFiles({ path: "profile" }).then(res => {
    let match = res.filter(file =>
      getNameFormPath(file['key']).slice(0,-4) == "profile" &&
      [".jpg", ".png"].some(ext =>
        ext == getExtensionByFileName(getNameFormPath(file['key']))));
    if (match.length > 0)
      callback(match[0]);
    else
      callback(null);
  })
}

export function getFileFormList(files, fileName) { //maybe no need in AWS
    return files.filter(file => getNameFormPath(file.key) == fileName)[0];
}

export function getNameFormPath(path) {
    let pathArray = path.split("/");
    let name = pathArray.pop();
    return name;
}

export function convertFilesToAntdTree(fileList, path) {
    return fileList.map(file => {
        console.log(path + file.fileName);
        if (file.type != "folder") {
            return { title: file.fileName, key: path + file.fileName, isLeaf: true };
        } else {
            return { title: file.fileName, key: path + file.fileName, icon: <FaFolder /> };
        }
    });
}

export function getExtensionByFileName(filename) {
    let index = filename.indexOf(".");
    if (index != -1)
        return filename.substring(index).toLowerCase();
    else
        return "NotFound";
}

// function convertFilesToAntdTree_(fileList,path){
//     return fileList.map(x => {
//         if(x.type != "folder"){
//             return { title: x.fileName, key: path + "/" + x.fileName ,isLeaf:true};
//         }else{
//             return { title: x.fileName, key: path + "/" + x.fileName ,children: convertFilesToAntdTree(fileList, path + "/" + x.fileName)};
//         }
//     });
// }