import React from "react";
import { Redrict, Redirect, Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Row,
  Col,
  Input
} from "reactstrap";
import "./SimpleHeader.scss"
import { FaHome } from "react-icons/fa";
import { uploadFile } from "Controller/File.js"

class TimelineHeader extends React.Component {
  pageJump = (url) => {
    if (this.props.refresh == true) {
      window.location.href = url;
    } else {
      this.props.history.push({
        pathname: url
      });
    }
  }

  newFolder = () => {
    let folder_name = prompt("Please enter folder name. 請輸入文件夾名稱", "");
    if (folder_name == null) {

    } else if (folder_name != "" && !(['/', '\\', ':', '*', '?', '"', '<', '>', '|'].some(unvalid_char => folder_name.includes(unvalid_char)))) {
      uploadFile(`${this.props.path === "" ? "" : this.props.path + "/"}${folder_name}`, null, {
        level: 'private'
      })
        .then(result => {
          console.log(result)
          window.location.href = window.location.href;
        })
        .catch(err => console.log(err));
    } else {
      alert("Filename not valid! '/\\:*?\"<>|' is not allowed!\n檔案名不合規! '/\\:*?\"<>|'不應存在! ")
    }
  }

  render() {
    return (
      <>
        <div className="header header-dark bg-info pb-6 content__title content__title--calendar">
          <Container fluid>
            <div className="header-body">
              <Row className="align-items-center py-4 ">

                <Col lg="6" xs="7">
                  {/* <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {
                      this.props.path == "" ? 
                      "Mesh Drive" : 
                      this.props.path.split("/")[this.props.path.split("/").length-1]
                    }
                  </h6>{" "} */}
                  <Breadcrumb
                    className="d-none d-md-inline-block ml-lg-4"
                    listClassName="breadcrumb-links breadcrumb-dark"
                  >
                    <BreadcrumbItem>
                      <Link to="/main"
                        className="flex-row justify-content-center a-item" style={{ lineHeight: "30px", verticalAlign: "middle", fontSize: "1rem" }}>
                        <FaHome style={{ paddingBottom: "5px", fontSize: "30px" }} />
                      </Link>
                      <i className="ni ni-bold-right text-white ml-2" style={{ lineHeight: "30px", verticalAlign: "middle", fontSize: "0.875rem" }}></i>
                    </BreadcrumbItem>
                    {
                      this.props.path.split("/")
                        .filter(val => val != "")
                        .map((val2, idx2) => {
                          // console.log(val2, idx2);
                          // console.log('this.path: ', this.props.path)
                          console.log('link: ', "/" + this.props.path.split("/").filter(val_ => val_ != "").slice(0, idx2 + 1).join("/"));
                          return (
                            <BreadcrumbItem>
                              <Link to={`/main/${this.props.path.split("/")
                                .filter(val_ => val_ != "")
                                .slice(0, idx2 + 1)
                                .join("/")}`
                              }
                                className="flex-row justify-content-center a-item"
                              // onClick={e => {
                              //   this.props.history.push({
                              //     pathname: '/main',
                              //     state: { path: this.props.path.split("/").slice(0,idx2+2).join("/") }
                              //   });
                              //   e.preventDefault()}
                              // }
                              >
                                {/* TODO: spacing, take off inline styling when possible */}
                                {
                                  val2
                                }
                              </Link>
                              <i className="ni ni-bold-right text-white ml-2 path-arrow" ></i>
                            </BreadcrumbItem>
                          )
                        })
                    }
                  </Breadcrumb>
                </Col>
                {!this.props.isfile ? <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <Button className="btn-neutral" color="default" size="sm" onClick={this.newFolder.bind(this)}>
                    New Folder
                    <br/>
                    新資科夾
                  </Button>
                  <Input type="file" id="selectedFile" color="default" size="sm" onChange={(e) => this.props.onDrop(e.target.files)} style={{ display: "none" }} />
                  <Button className="btn-neutral" color="default" size="sm" onClick={() => document.getElementById('selectedFile').click()} >
                    Upload
                    <br/>
                    上傳
                  </Button >
                </Col> : ""}
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string
};

export default TimelineHeader;
