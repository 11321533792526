import React from "react";
// react plugin that prints a given react component
// import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  FaFile, FaFolder, FaTrash,
  FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint,
  FaFileMedicalAlt, FaFileCsv, FaDownload, FaFileSignature,
  FaFileAlt, FaFileArchive, FaFileImage, FaFileImport, FaFileCode
} from "react-icons/fa";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components 
import ResultHeader from "components/Headers/ResultHeader.js";
import { listFiles, getExtensionByFileName, listName } from "Controller/File.js"
import { dataTable } from "dummyData/CusTableDefault";
import './CusTable.scss'
import Dropzone from "react-dropzone";
import _ from 'lodash';
import upload_img from "assets/img/upload.png"
import { fileContent } from "Controller/File";
import loading from "assets/img/loading3.gif"
import error from "assets/img/404.gif"
import './Loading.scss'


const { SearchBar } = Search;


class Loading extends React.Component {
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  constructor(props) {
    super(props);
    this.state = {
      timeOver: false
    }
    this.incrementTime = this.incrementTime.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
  }

  componentDidMount() {
    this.timeId = setInterval(() => {
      this.incrementTime();
    }, 20000);
  }
  incrementTime() {
    this.setState({ timeOver: true });
  }

  render() {
    return (
      <>
        <ResultHeader path={this.props.path} {...this.props} />
        <Container className="mt--6" fluid>
          <Row >
            <div className="col">
              <Card >
                {
                  !this.state.timeOver
                    ?
                    <div className="loadingGIF justify-content-center row"><img src={loading} /></div>
                    :
                    <div className="loadingGIF justify-content-center row p-1"><img src={error} /></div>
                }
              </Card>
            </div>
          </Row>
        </Container>
      </>
    )
  }
}

export default Loading;
