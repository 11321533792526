
import { Auth } from 'aws-amplify';

let i = 0;
let errnum = 0;
async function create(email, password) {
    console.log("try " + i);
    try {
        await Auth.confirmSignUp(email, password);
    } catch (err) {
        errnum=errnum+1;
        console.log(email);
        console.log(JSON.stringify(err));
    }
    i=i+1;
}

async function modify(uname, pword, name, phone_number, email, address, country, city) {
    i += 1;
    let info = {
        name,
        phone_number,
        email,
        address,
        "custom:country": country,
        "custom:city": city
    };
    try {
        await Auth.signIn(uname, pword);
        let user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(
            user, info
        );
        await Auth.signOut();
    } catch (err) {
        console.log(uname);
        errnum += 1;
    }
}

export default async function modifyProfile() {
    // await modify(uname, pword, name, phone_number, email, address, "HKG", "Hong Kong");
}

async function createUser() {
    await create("ghkmbayltd@gmail.com", "201508");
    // await create("info@starbus.com.hk", "Ace13579");
    await create("lancialimited@outlook.com", "831358");
    await create("onberreflexology@outlook.com", "156933");
    // await create("crystalicecpy@gmail.com", "Ace13579");
    await create("skytransportationltd@outlook.com", "382797");
    await create("richadvancecorporate@outlook.com", "89496");
    await create("chasinglifesportsclub@outlook.com", "485317");
    await create("ghairspasalon@outlook.com", "968751");
    await create("simonlee_02@hotmail.com", "157513");
    // await create("kwaifung2000@yahoo.com", "Ace13579");
    await create("thaithaibuddha@gmail.com", "900814");
    await create("vivoacademymusic@gmail.com", "810827");
    await create("kclogisticsinterltd@gmail.com", "507110");
    await create("cascowayltd@gmail.com", "812218");
    await create("HPHPHPinvest@gmail.com", "155094");
    // await create("elitelearncentre@gmail.com", "Ace13579");
    await create("yathingtradingcpy@gmail.com", "834365");
    await create("moonooncompany@gmail.com", "336970");
    await create("innergyinteriordesign@gmail.com", "520315");
    await create("saikungrickyhut00@gmail.com", "381567");
    // await create("goldenpetpetser@gmail.com", "Ace13579");
    await create("ACpany@gmail.com", "901724");
    // await create("ddbnbltd@gmail.com", "Ace13579");
    await create("eastftf@gmail.com", "670023");
    await create("s06sunyat@gmail.com", "534937");
    await create("w01wellelem@hotmail.com", "621489");
    await create("wchlimit@gmail.com", "497896");
    await create("onlyone20211314@hotmail.com", "186616");
    // await create("yuncheehop@gmail.com", "Ace13579");
    // await create("ccwaterproof@gmail.com", "Ace13579");
    // await create("fusionmeatlf03@gmail.com", "Ace13579");
    await create("sapient2099@gmail.com", "730022");
    await create("virgooo2018@gmail.com", "672939");
    await create("billicreation@gmail.com", "285109");
    await create("emineworlde05@gmail.com", "635410");
    await create("leemingcom3@gmail.com", "537215");
    await create("info@hkaic.com", "62505");
    console.log(i + "/" + errnum);
}