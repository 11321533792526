import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText
} from "reactstrap";
import { useHistory } from "react-router-dom";
import gitLogo from "assets/img/icons/common/github.svg"
import googleLogo from "assets/img/icons/common/google.svg"
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import { Auth } from 'aws-amplify';

import "./Register.scss"

class ResetPassword extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      errorMsg: null,
      username: this.props.match.params.name ? this.props.match.params.name : this.props.location.state ? this.props.location.state.email : "",
      password: '',
      verificationCode: this.props.match.params.code ? this.props.match.params.code : "",
      confirmPW: '',
      email: ''
    }
    this.onChange = this.onChange.bind(this)
    this.backToSignIn = this.backToSignIn.bind(this)
    this.forgotPasswordSubmit = this.forgotPasswordSubmit.bind(this)
    this.signOut = this.signOut.bind(this)
    this.getUserEmail = this.getUserEmail.bind(this)
  }

  componentDidMount() {
    this.getUserEmail()
  }

  onChange(e) {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  }

  backToSignIn() {
    this.props.history.push("/auth/login");
  }

  async getUserEmail() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      this.setState({ email: user.attributes.email });
    }
    catch (err) {
      console.log(err.message)
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
      this.props.history.push({
        pathname: "/auth/login"
      });
    }
    catch (error) {
      console.log('error signing out: ', error);
    }
  }

  handleError(err) {
    //input is row['fileName'] already

    console.log(err.message);
    //   if (fileType == "folder" && !isfolder)
    //     return <FaFile className="fileIcon" />;

    switch (err.message) {
      case "Username cannot be empty":
        this.setState({ errorMsg: "Username cannot be empty \n 用户名稱不能為空值" });
        break;
      case "Username/client id combination not found.":
        this.setState({ errorMsg: "Username is not find \n 找不到用戶名" });
        break;
      case "Cannot reset password for the user as there is no registered/verified email or phone_number":
        this.setState({ errorMsg: "Username is not find \n 找不到用戶名" });
        break;
      case "Confirmation code cannot be empty":
        this.setState({ errorMsg: "Verification code cannot be empty \n 驗證碼不能為空值" });
        break;
      case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      case "Error creating account":
        this.setState({ errorMsg: "please enter Username and Password \n 請輸入用户名稱和密碼" });
        break;
      case "Password did not conform with policy: Password must have uppercase characters":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      case "Password did not conform with policy: Password must have lowercase characters":
        this.setState({ errorMsg: "Password must be greater than or equal to 6 \n 密碼必須大於或等於6 \n Password must have uppercase and lowercase characters \n 密碼必須包含大寫和小寫英文字符 \n " });
        break;
      case "Password cannot be empty":
        this.setState({ errorMsg: "Password cannot be empty \n 密碼不能為空值" });
        break;
      default:
        this.setState({
          error: err,
          errorMsg: err.message,
          resended: null
        })
    }
  }

  async forgotPasswordSubmit(e) {
    e.preventDefault()
    // if (this.state.password !== this.state.confirmPW) {
    //   this.setState({ errorMsg: "Passwords don't match." });
    // }
    // else {
    try {
      await Auth.forgotPasswordSubmit(this.state.username, this.state.verificationCode, this.state.password);
      alert("The password is changed successfully, you can login again!\n密碼更改成功, 你可以重新登入!");
      this.signOut();
      this.backToSignIn();
    }
    catch (error) {
      this.handleError(error);
    }
    // }
  }

  render() {
    return (
      <>
        <AuthHeader
          title="Reset password"
          lead=""
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="6" md="8">
              <Card className="bg-secondary border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-left text-muted mb-4">
                    <h3>Reset password 重設密碼</h3>
                  </div>
                  <Form role="form">
                    {((!this.props.match.params.name) && (!this.props.location.state)) &&
                      <FormGroup>
                        <div className="ml-2">
                          <h5>Email 電郵地址</h5>
                        </div>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            defaultValue={
                              this.props.match.params.name
                                ?
                                this.props.match.params.name
                                :
                                this.props.location.state
                                  ?
                                  this.props.location.state.email
                                  :
                                  ""}
                            name="username"
                            onChange={this.onChange}
                            placeholder="Example: test@test.com"
                            type="text" />
                        </InputGroup>
                      </FormGroup>
                    }
                    <FormGroup>
                      <div className="ml-2">
                        <h5>New Password 新密碼</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          onChange={this.onChange}
                          placeholder="Min 6 char or num, at least 1 upper and 1 lower"
                          type="password"
                        />
                      </InputGroup>
                    </FormGroup>
                    {/* <FormGroup>
                      <div className="ml-2">
                        <h5>Confirm Password 確認新密碼</h5>
                      </div>
                      <InputGroup className="input-group-merge input-group-alternative mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="confirmPW"
                          onChange={this.onChange}
                          placeholder="Min 6 char or num, at least 1 upper and 1 lower"
                          type="password" />
                      </InputGroup>
                    </FormGroup> */}
                    {!this.props.match.params.code &&
                      <FormGroup>
                        <div className="ml-2">
                          <h5>Verification Code 驗證碼</h5>
                        </div>
                        <InputGroup className="input-group-merge input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="verificationCode"
                            onChange={this.onChange}
                            placeholder="Enter your code"
                            type="text"
                          />
                        </InputGroup>
                      </FormGroup>
                    }
                    {
                      this.state.errorMsg
                        ?
                        <FormText color="danger">
                          {this.state.errorMsg.split("\n").map(word => <>{word}<br /></>)}
                        </FormText>
                        :
                        null
                    }
                    <Row className="align-items-center">
                      <Col>
                        <Button className="mt-2 btn px-5 float-right " color="info" type="button" onClick={this.forgotPasswordSubmit}>
                          Reset
                          <br />
                          重設
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

export default ResetPassword;
