import React, { useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Peer from "simple-peer";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container
} from "reactstrap";
import NormalNavbar from "components/Navbars/NormalNavbar.js";
import { Auth } from 'aws-amplify';
import './VideoChat.scss'
import _ from "lodash"

import { FiVideo, FiVideoOff, FiVolume2, FiVolumeX, FiPhone } from "react-icons/fi";

const Video = (props) => {
  const ref = useRef();

  useEffect(() => {
    props.peer.on("stream", stream => {
      ref.current.srcObject = stream;
    })
  }, []);

  let rchar = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 2).toUpperCase();

  return (
    <div className="peerVideoContainer">
      <video className="partnerVideo" playsInline autoPlay ref={ref} />
      <div className="peerNameContainer">
        <div className="peerNameText">
          {rchar}
        </div>
      </div>
    </div>
    
  );
}


const videoConstraints = {
  height: window.innerHeight / 2,
  width: window.innerWidth / 2
};

const VideoChat = (props) => {
  const [peers, setPeers] = useState([]);
  const socketRef = useRef();
  const userVideo = useRef();
  const peersRef = useRef([]);

  // const canvas = useRef();
  // let ctx = null;

  const [isMuted, setIsMuted] = useState(true)
  const [isVideoOff, setIsVideoOff] = useState(true)

  const streamRef = useRef()

  const [user, setUser] = useState({})

  // const roomID = props.match.params.roomID;
  const roomID = '256';

  async function getUserName() {
    try {
      let user = await Auth.currentAuthenticatedUser();
      setUser({ email: user.attributes.email, name: user.attributes.name });
    } catch (error) {
      alert(error)
    }

    console.log('user', user);
  }

  useEffect(async () => {
    // socketRef.current = io.connect("/");
    socketRef.current = io.connect("https://driveclub.hk");
    // getNewStream()
    console.log('Original Stream:', streamRef);
    // let newStream = await navigator.mediaDevices.getUserMedia({ video: !isVideoOff, audio: true })
    // setStream(newStream)
    let newStream = await getNewStream()
    streamRef.current = newStream
    userVideo.current.srcObject = streamRef.current;

    getUserName()
    initSocketAndPeers(streamRef)

    toggleVideo(true)
    toggleAudio(true)

    // // dynamically assign the width and height to canvas
    // const canvasEle = canvas.current;
    // canvasEle.width = canvasEle.clientWidth;
    // canvasEle.height = canvasEle.clientHeight;

    // // get context of the canvas
    // ctx = canvasEle.getContext("2d");
    // let rchar = Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 2).toUpperCase();
    // writeText({ text: rchar, x: canvasEle.width / 2, y: canvasEle.height/2 });

  }, [])

  function initSocketAndPeers(streamRef){
    socketRef.current.emit("join room", roomID);

    socketRef.current.on("all users", users => {
      console.log('received user list: ', users);
      const peers = [];
      users.forEach(userID => {
        const peer = createPeer(userID, socketRef.current.id, streamRef.current);
        peersRef.current.push({
          peerID: userID,
          peer,
        })
        peers.push({
          peerId: userID,
          peer
        });
      })
      setPeers(peers);
    })

    socketRef.current.on("user joined", payload => {
      console.log('user joined', payload);
      const peer = addPeer(payload.signal, payload.callerID, streamRef.current);
      peersRef.current.push({
        peerID: payload.callerID,
        peer,
      })

      const peerObj = {
        peer,
        peerID: payload.callerID,
      }

      setPeers(users => [...users, peerObj]);

      console.log('peersRef', peersRef);
      console.log('peers', peers);
    });

    socketRef.current.on("receiving returned signal", payload => {
      const item = peersRef.current.find(p => p.peerID === payload.id);
      item.peer.signal(payload.signal);
    });

    socketRef.current.on("someone disconnecting", payload => {
      console.log('server notify: someone is disconnected, ID:', payload.id);
      const item = peersRef.current.find(p => p.peerID === payload.id);
      if (item) {
        item.peer.destroy()
      }

      const peers = peersRef.current.filter(p => p.peerID !== payload.id)
      peersRef.current = peers;
      setPeers(peers)


      console.log('peersRef', peersRef);

      // const indexToRemove = peersRef.current.findIndex(p => p.peerID === payload.id);
      // peersRef.current.splice(indexToRemove, 1);
      // console.log('peersRef', peersRef);

      console.log('peers', peers);

    })
  }

  useEffect(()=>{
    console.log('StreamRef being updated: ', streamRef);
  }, [streamRef])

  // useEffect(async () => {
  //   // navigator.mediaDevices.getUserMedia({ video: videoConstraints, audio: true }).then(stream => {

  //   // let newStream = await navigator.mediaDevices.getUserMedia({ video: !isVideoOff, audio: true })
  //   // setStream(newStream)

  //   // let stream = await navigator.mediaDevices.getUserMedia({ video: !isVideoOff, audio: true })

  //   // navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then(stream => {
  //   // })
  // }, []);

  function disconnectSocket(){
    console.log("I'm disconnecting socket");
    socketRef.current.disconnect()
  }

  async function getNewStream() {
    let newStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })

    console.log('in getNewStream', newStream);
    return newStream
  }
  

  async function toggleVideo(value){
    setIsVideoOff(value)

    // using canvas
    // let canvasStream = canvas.current.captureStream()
    // let blankVideoTrack = canvasStream.getVideoTracks()[0]

    // console.log('in toogleVideo, isVideoOff value: ', value);
 
    let videoTrack = _.find(userVideo.current.srcObject.getTracks(), { readyState: 'live', kind: 'video' })
    videoTrack.enabled = !value
      // videoTrack.stop()

      // trigger replace track to all peers
  
      // console.log('getting new stream and replace');
      // let newStream = await getNewStream()
      // streamRef.current = newStream
      // userVideo.current.srcObject = newStream;

    // videoTrack.enabled = false
    // videoTrack.stop()
  }

  function toggleAudio(value){
    setIsMuted(value)
    // console.log('toggling audio');
    // console.log(userVideo.current.srcObject);
    // console.log(userVideo.current.srcObject.getTracks()); 

    let audioTrack = _.find(userVideo.current.srcObject.getTracks(), { readyState: 'live', kind: 'audio' })
    audioTrack.enabled = !value
  }


  function createPeer(userToSignal, callerID, stream) {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
    });

    peer.on("signal", signal => {
      socketRef.current.emit("sending signal", { userToSignal, callerID, signal })
    })

    return peer;
  }

  function addPeer(incomingSignal, callerID, stream) {
    const peer = new Peer({
      initiator: false,
      trickle: false,
      stream,
    })

    peer.on("signal", signal => {
      socketRef.current.emit("returning signal", { signal, callerID })
    })

    peer.signal(incomingSignal);

    return peer;
  }

  // write test function for Canvas
  // const writeText = (info, style = {}) => {
  //   const { text, x, y } = info;
  //   const { fontSize = 20, fontFamily = 'Arial', color = 'white', textAlign = 'center', textBaseline = 'top' } = style;

  //   ctx.beginPath();
  //   ctx.font = fontSize + 'px ' + fontFamily;
  //   ctx.textAlign = textAlign;
  //   // ctx.textBaseline = textBaseline;
  //   ctx.fillStyle = color;
  //   ctx.fillText(text, x, y);
  //   ctx.stroke();
  // }

  return (
    <div
      className=""
      style={{
        minHeight: "10%",
      }}
    >
      <span className="mask" style={{ height: "30%", backgroundColor: "#11cdef" }} />
      <NormalNavbar
        {...props}
      // brandText={this.getBrandText(this.props.location.pathname)}
      />
      <div className="settings-container row justify-content-center" >
        <Card className="main-card-video">
          <CardHeader className="py-2">
            <Row className="align-items-center">
              <Col xs="12">
                <h4 className="mb-0">Video Conference 視像會議</h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="p-4">
            <div>
              {/* <div>canvas</div>
              <canvas ref={canvas} className="w-100" style={{backgroundColor: 'black', height: '300px'}}></canvas> */}

              <div className="userVideoContainer">
                <video className="userVideo"
                  height={"100%"}
                  ref={userVideo}
                  // muted={isMuted ? true : null} 
                  muted
                  autoPlay playsInline
                />
                <div className="userNameContainer">
                  <div className="userNameText">{user?.name}</div>
                </div>
              </div>
    
              {/* { this.state.stream ? 
                <video className="userVideo" muted ref={userVideo} autoPlay playsInline />
                : 
                <div className="userVideoLoadingText">Loading Video...</div>
              } */}
              <Row className="justify-content-center mt-1">
                <Col className="text-center">
                  <Button type="button"
                    style={isVideoOff ? { backgroundColor: '#ecb8c2da' } : { backgroundColor: '#f4f5f7' }}
                    onClick={() => {
                      // setIsVideoOff(!isVideoOff)
                      toggleVideo(!isVideoOff)
                    }}
                  >
                    <FiVideoOff size={18} style={{ color: isVideoOff ? "white" : "black"}} />
                  </Button>
                </Col>

                <Col className="text-center">
                  <Button color="danger" 
                    type="button"  
                    onClick={() => {
                      let r = window.confirm("Are you sure to disconnect from the meeting?");
                      if (r === true) {
                        disconnectSocket()
                        props.history.push("/main");
                      }
                      
                    }}
                  >
                    <FiPhone size={18}/>
                  </Button>
                </Col>

                <Col className="text-center">
                  <Button type="button"
                    style={isMuted ? { backgroundColor: '#ecb8c2da' } : { backgroundColor: '#f4f5f7' }}
                    onClick={() => {
                      // setIsMuted(!isMuted)
                      toggleAudio(!isMuted)
                    }}
                  >
                    <FiVolumeX size={18} style={{ color: isMuted ? "white" : "black" }} />
                  </Button>
                </Col>

              </Row>
            </div>
          </CardBody>
        </Card>
      </div>

      <Container className="conference-container mt-0">
        <div className="text-center my-1">
          Participants:
        </div>
        <Row className="justify-content-center">
          {peers.map((peer, index) => {
            return (
              <Video key={peer.peerID} peer={peer.peer} />
            );
          })}
        </Row>
      </Container>
    </div>
  );
}

export default VideoChat;