import React from "react";
// react plugin that prints a given react component
// import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Loading from "components/Body/Loading"
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import {
  FaFile, FaFolder, FaTrash,
  FaFilePdf, FaFileWord, FaFileExcel, FaFilePowerpoint,
  FaFileMedicalAlt, FaFileCsv, FaDownload, FaFileSignature,
  FaFileAlt, FaFileArchive, FaFileImage, FaFileImport, FaFileCode
} from "react-icons/fa";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { 
  listFiles, getExtensionByFileName, listName, uploadFile, 
  deleteFile, fileContent } from "Controller/File.js"
import { dataTable } from "dummyData/CusTableDefault";
import './CusTable.scss'
import Dropzone from "react-dropzone";
import _ from 'lodash';
import upload_img from "assets/img/upload.png"

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

const fileExtTypeMap = [
  {
    type: 'image',
    ext: ['png', 'jpg', 'jpeg']
  },
  {
    type: 'pdf',
    ext: ['pdf']
  },
  {
    type: 'word',
    ext: ['doc', 'docx']
  },
  {
    type: 'excel',
    ext: ['xlsx', 'xlsm', 'xlsb', 'xltx', 'xltm', 'xls']
  },
  {
    type: 'csv',
    ext: ['csv']
  },
  {
    type: 'ppt',
    ext: ['ppsx', 'ppt', 'pptm', 'pptx']
  },
  {
    type: 'media',
    ext: ['mp3', 'wav', 'mp4', 'mov', 'avi', 'flv']
  },
  {
    type: 'txt',
    ext: ['txt']
  },
  {
    type: 'zip',
    ext: ['zip', '7z']
  },
  {
    type: 'html',
    ext: ['htm', 'html']
  },
  {
    type: 'folder',
    ext: ['NotFound']
  }
]

class CusTables extends React.Component {
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  constructor(props) {
    super(props);
    // console.log(this.props.data);
    // console.log(this.props.path);
    this.state = {
      alert: null,
      currentFolder: null,
      uploading: false,
      uploadmessage: "Release here\n\n放開至此處"
      // currentFolder:listFiles({path: this.props.location.pathname}).files,
    };
    this.props.genData(this.props.path, this.setData.bind(this));
    this.switchFileIcons = this.switchFileIcons.bind(this)
  }
  componentDidMount() {
    this.props.genData(this.props.path, this.setData.bind(this));
  }
  setData(data) {
    this.setState({ currentFolder: listName(this.props.path, data) });
  }
  setProgress = (progress) => {
    this.setState({
      uploading: true,
      uploadmessage: `Uploaded: ${progress.loaded}/${progress.total}\n\n更新中`
    });
  }
  deleteObject(path, isFolder, size, fileName) {
    if (path[0] == "/") {
      path = path.slice(1);
    }
    console.log(path);
    if (window.confirm('Are you sure? 確定?') === true) {
      if ((size == 0 && listName(path, this.props.data).length > 1 && isFolder) || (size != 0 && isFolder)) {
        alert("Please empty the folder first! 請先清空文件夾!")
        window.location.href = window.location.href;
      } else if (fileName == "profile") {
        alert("Cannot delete profile folder! 個人資料夾不能被刪除!")
      } else {
        deleteFile(path, { level: "private" }).then(
          res => {
            this.props.genData(this.props.path, this.setData.bind(this));
          });
      }
    }
  }
  download(key, isFolder) {
    if (key[0] == "/") {
      key = key.slice(1);
    }
    console.log(key);
    if (isFolder) {
      alert('Cannot download folder! 資料夾不能下載!')
    } else {
      fileContent(key).then(url => {
        window.location.href = url;
      });
    }
  }

  onDrop = (acceptedFiles) => {
    console.log(acceptedFiles)
    if (acceptedFiles.length == 1) {
      uploadFile(`${this.props.path === "" ? "" : this.props.path + "/"}${acceptedFiles[0].name}`, acceptedFiles[0], {
        level: 'private',
        progressCallback: this.setProgress.bind(this)
      })
        .then(result => {
          this.setState({
            uploading: false,
            uploadmessage: "Release here\n\n放開至此處"
          })
          console.log(result)
          this.props.genData(this.props.path, this.setData.bind(this));
        })
        .catch(err => console.log(err));
    }
    else {
      alert("Only allow single file upload! 祇准許上載一個文件!")
    }
  }

  checkIfAny = (filename, extList) => {
    return extList.includes(getExtensionByFileName(filename));
  }

  imgGetter = (path) => {
    if (path === "/main/0.jpg")
      return "https://cdn.hk01.com/di/media/images/3841315/org/44ab0f3b45047ad890648f9600fe8f28.png/Oyt8V9Yk9zp1_31KxuZa1xI1wavORri5FfXIBRX1yAU?v=w1920";
    if (path === "/main/1.PNG")
      return "https://i.redd.it/014mifuhemm41.jpg";
  }

  dateFormatter(cell, row) {
    if (row['isFolder']) {
      return (<span> {" "} </span>);
    }
    return (<span> {new Date(cell).toString()} </span>);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.path != nextProps.path) {
      console.log(nextProps);
      this.props.genData(this.props.path, this.setData.bind(this));
    }
  }

  copyToClipboardAsTable = el => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    this.setState({
      alert: (
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.setState({ alert: null })}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Copied to clipboard!
        </ReactBSAlert>
      )
    });
  };

  switchFileIcons(fileName, isfolder) {
    //input is row['fileName'] already

    let filExtWithDot = getExtensionByFileName(fileName)

    let ext = filExtWithDot === "NotFound" ? filExtWithDot : filExtWithDot.split('.').pop()

    let fileTypeMap = _.find(fileExtTypeMap, function (o) {
      return (o.ext).indexOf(ext) > -1
    })

    let fileType = fileTypeMap ? fileTypeMap.type : "undefined"

    console.log(fileType);
    if (fileType == "folder" && !isfolder)
      return <FaFile className="fileIcon" />;

    switch (fileType) {
      case "folder":
        return <FaFolder className="folder fileIcon" />
      case "image":
        return <FaFileImage className="image fileIcon" />
      case "pdf":
        return <FaFilePdf className="pdf fileIcon" />
      case "word":
        return <FaFileWord className="word fileIcon" />
      case "excel":
        return <FaFileExcel className="excel fileIcon" />
      case "ppt":
        return <FaFilePowerpoint className="ppt fileIcon" />
      case "csv":
        return <FaFileCsv className="csv fileIcon" />
      case "media":
        return <FaFileMedicalAlt className="alt fileIcon" />
      case "txt":
        return <FaFileAlt className="txt fileIcon" />
      case "zip":
        return <FaFileArchive className="zip fileIcon" />
      case "html":
        return <FaFileCode className="fileIcon" />
      default:
        return <FaFile className="fileIcon" />
    }
  }

  getSize(cell, row) {
    console.log(row)
    if (row['isFolder'])
      return <span>{" "}</span>
    else
      return <span>{cell}</span>
  }

  render() {
    return this.state.currentFolder == null ? <Loading /> : (
      <>
        {this.state.alert}
        <SimpleHeader path={this.props.path} {...this.props} onDrop={this.onDrop.bind(this)} />
        <Container className="mt--6" fluid>
          <Row >
            <div className="col">
              <Card>
                {/* <CardHeader>
                  <h3 className="mb-0">React Bootstrap Table 2</h3>
                  <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p>
                </CardHeader> */}
                <ToolkitProvider
                  data={this.state.currentFolder.map((file, index) => {
                    file['nth'] = index;
                    return file;
                  }).filter(x => x['fileName'] != "")}
                  keyField="name"
                  columns={
                    [{
                      dataField: "fileName",
                      text: "fileName 檔案名稱",
                      sort: true,
                      filterValue: (cell, row) => row['fileName'],
                      formatter: (cell, row) => {
                        //folder icon
                        return (
                          <div className="cellStyle">
                            {this.switchFileIcons(row['fileName'], row['isFolder'])}{" "}
                            <span className="cellVerAlign-middle">{cell} </span>
                          </div>
                        );
                      }
                    },
                    // {
                    //   dataField: "type",
                    //   text: "type",
                    //   sort: true
                    // },
                    {
                      dataField: "lastModified",
                      text: "upload time 上傳時間",
                      sort: true,
                      formatter: this.dateFormatter,
                      filterValue: (cell, row) => row['fileName'],
                      classes: 'cellVerAlign-middle'
                    },
                    {
                      dataField: "size",
                      text: "size 大小",
                      sort: true,
                      filterValue: (cell, row) => row['fileName'],
                      classes: 'cellVerAlign-middle',
                      filterValue: (cell, row) => row['fileName'],
                      formatter: (cell, row) => {
                        return (<span>{this.getSize(cell, row)}</span>)
                      }
                    },
                    {
                      text: "Action 操作",
                      sort: true,
                      formatter: (cell, row) => {
                        return (
                          <span className="cellVerAlign-middle">
                            <FaTrash className="mr-2 hover_cursor" onClick={() => this.deleteObject(row['filePath'], row["isFolder"], row['size'], row['fileName'])} /> {" "}
                            <FaDownload className="mr-2 hover_cursor" onClick={() => this.download(row['filePath'], row["isFolder"])} />
                          </span>
                        )
                      }
                    },
                    ]
                  }
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search Current Folder:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <Dropzone onDrop={this.onDrop.bind(this)}>
                        {
                          (dragprops) =>
                            <div>
                              <div
                                {
                                ...dragprops.getRootProps({
                                  onClick: event => event.stopPropagation()
                                })
                                }
                              >
                                <input {...dragprops.getInputProps()} />
                                {
                                  dragprops.isDragActive ?
                                    <div>Drop the files... 放開...<br /></div> :
                                    <div>Drag 'n' drop some files to the Table or this text <br /></div>
                                }
                                <div style={{ position: "relative" }}>
                                  <div style={{ bottom: "50%", left: "calc(50% - 200px)", position: "absolute", display: dragprops.isDragActive || this.state.uploading ? "block" : "none" }}>
                                    <div style={{ width: "400px" }}>
                                      <center style={{ fontSize: "14px" }}>
                                        {this.state.uploadmessage.split("\n").map(word => <>{word}<br /></>)}<br />
                                        <img width="80" src={upload_img} />
                                      </center>
                                    </div>
                                  </div>
                                  <div style={{ opacity: dragprops.isDragActive || this.state.uploading ? "0.3" : "1" }}>
                                    <BootstrapTable
                                      {...props.baseProps}
                                      bootstrap4={true}
                                      pagination={pagination}
                                      bordered={false}
                                      // rowStyle={ {lineHeight:"6px"} }
                                      rowClasses="hover_cursor"
                                      hover
                                      rowEvents={
                                        {
                                          onDoubleClick: (e, row, rowIndex) => {
                                            // console.log(this.props);
                                            //("/main" + (this.props.path == "" ? `/${row.fileName}` : `${this.props.path}/${row.fileName}`));
                                            this.props.history.push({
                                              pathname: "/main" + (this.props.path == "" ? `/${row.fileName}` : `/${this.props.path}/${row.fileName}`)
                                              //   // state: { path: this.props.path == "" ? row.fileName : this.props.path+"/"+row.fileName }
                                            });
                                          },

                                          onContextMenu: (e, row, rowIndex) => {
                                            if (this.checkIfAny(row['fileName'], [".jpg", ".png"])) {
                                              fileContent(row['key']).then(url => {
                                                document.getElementById("contextmenu").style.top = e.clientY + "px";
                                                document.getElementById("contextmenu").style.left = e.clientX + "px";
                                                document.getElementById("contextmenu").style.display = "block";
                                                document.getElementById("body").addEventListener("click", function () {
                                                  if (document.getElementById("contextmenu")) {
                                                    document.getElementById("contextmenu").style.display = "none";
                                                  }
                                                });
                                                document.getElementById("imgContext").src = url;
                                              })
                                            }
                                            e.preventDefault();
                                          }
                                        }
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                        }
                      </Dropzone>
                    </div>
                  )}
                </ToolkitProvider>
                <div id="contextmenu" className="contextMenu">
                  <img id="imgContext" width="200" />
                </div>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default CusTables;
